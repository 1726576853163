import { put } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions/index';

import {
    getSessionToken,
    getUserEmail,
    getUsergroupType,
} from '../../constants/session';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const resource = process.env.REACT_APP_DASHBOARD;
// limit determined by hyphen; may be user pref later
const limit = 5;

// builder: returns batches and activities
// supplier: returns payments
export default function* getDashboardSaga(action) {
    try {
        yield put(actions.dashboardStart());
        const { filterType, filterValue } = action.data;
        const email = getUserEmail();
        const usergroupType = getUsergroupType();
        const url = baseUrl + resource;

        const sessionToken = getSessionToken();
        const response = yield axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionToken,
            },
            params: {
                username: email,
                limit,
                filter_type: filterType,
                filter_value: filterValue,
            },
        });

        const data = {
            usergroupType,
            batches: response.data.result.batches
                ? response.data.result.batches
                : null,
            activity: response.data.result.activity
                ? response.data.result.activity
                : null,
            payments: response.data.result.payments
                ? response.data.result.payments
                : null,
            pie_chart: response.data.result.pie_chart
                ? response.data.result.pie_chart
                : null,
            bar_chart: response.data.result.bar_chart
                ? response.data.result.bar_chart
                : null,
            analytics: response.data.result.analytics
                ? response.data.result.analytics
                : null,
        };

        yield put(actions.dashboardSuccess(data));
    } catch (error) {
        // error.response.status === 401 then boot them
        yield put(actions.dashboardFail(error));
    }
}
