import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const initialState = {
    isLoading: false,
    mode: 'dark',
    menu: false,
    dialogStatus: false,
    verificationDialogStatus: false,
    recaptcha: '',
    navBarActiveSection: '',
};

const themeChange = (state, action) =>
    updateObject(state, {
        mode: action.data,
    });
const menuOpen = (state, action) =>
    updateObject(state, {
        menu: action.data,
    });
const navBarActiveChange = (state, action) =>
    updateObject(state, {
        navBarActiveSection: action.data,
    });
const dialogOpen = (state, action) =>
    updateObject(state, {
        dialogStatus: action.data,
    });
const verificationDialogOpen = (state, action) =>
    updateObject(state, {
        verificationDialogStatus: action.data.dialogStatus,
        recaptcha: action.data.recaptcha ?? state.recaptcha,
    });

const startLoader = (state) =>
    updateObject(state, {
        isLoading: true,
    });
const stopLoader = (state) =>
    updateObject(state, {
        isLoading: false,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.THEME_CHANGE:
            return themeChange(state, action);
        case actionTypes.MENU_OPEN:
            return menuOpen(state, action);
        case actionTypes.NAVBAR_ACTIVE_CHANGE:
            return navBarActiveChange(state, action);
        case actionTypes.DIALOG_OPEN:
            return dialogOpen(state, action);
        case actionTypes.VERIFICATION_DIALOG_OPEN:
            return verificationDialogOpen(state, action);
        case actionTypes.START_LOADER:
            return startLoader(state);
        case actionTypes.STOP_LOADER:
            return stopLoader(state);
        default:
            return state;
    }
};

export default reducer;
