import * as actionTypes from '../types';

export const walletPayStart = () => ({
    type: actionTypes.WALLETPAY_START,
});

export const walletPaySuccess = (data) => ({
    type: actionTypes.WALLETPAY_SUCCESS,
    data,
});

export const walletPayFail = (error) => ({
    type: actionTypes.WALLETPAY_FAIL,
    error,
});

export const getWalletPay = (startIndex, stopIndex, searchFilter) => ({
    type: actionTypes.GET_WALLETPAY,
    startIndex,
    stopIndex,
    searchFilter,
});
export const realTimeBatchStart = () => ({
    type: actionTypes.REAL_TIME_BATCH_START,
});

export const realTimeBatchSuccess = (data) => ({
    type: actionTypes.REAL_TIME_BATCH_SUCCESS,
    data,
});

export const realTimeBatchFail = (error) => ({
    type: actionTypes.REAL_TIME_BATCH_FAIL,
    error,
});
export const payDetailsDialogStatus = (status) => ({
    type: actionTypes.PAY_DETAIL_DIALOG_STATUS,
    status,
});

export const putRealTimeBatch = (data) => ({
    type: actionTypes.PUT_REAL_TIME_BATCH,
    data,
});
export const postBatchCsv = (data) => ({
    type: actionTypes.POST_BATCH_CSV,
    data,
});
export const postBatchCsvStart = () => ({
    type: actionTypes.POST_BATCH_CSV_START,
});

export const postBatchCsvSuccess = (data) => ({
    type: actionTypes.POST_BATCH_CSV_SUCCESS,
    data,
});

export const postBatchCsvFail = (error) => ({
    type: actionTypes.POST_BATCH_CSV_FAIL,
    error,
});
