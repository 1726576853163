import * as actionTypes from './types';

export const batchesStart = () => ({
    type: actionTypes.BATCHES_START,
});

export const batchesSuccess = (data) => ({
    type: actionTypes.BATCHES_SUCCESS,
    data,
});

export const batchesFail = (error) => ({
    type: actionTypes.BATCHES_FAIL,
    error,
});

export const getBatches = (data) => ({
    type: actionTypes.GET_BATCHES,
    data,
});
export const batchSortValues = (data) => ({
    type: actionTypes.BATCH_SORT_VALUES,
    data,
});
export const batchTableFilter = (data) => ({
    type: actionTypes.BATCH_FILTER_VALUES,
    data,
});
