import { put, call } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../../actions/index';

import { getSessionToken, getUserEmail } from '../../../constants/session';
import { alertDialogSetSaga } from '../alertDialog';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getUsergroupWalletPayResource = process.env.REACT_APP_USERGROUP_WALLETPAY;
const putRealTimeBatchResource = process.env.REACT_APP_POST_WALLETPAY_BATCH;
const postBatchCsvResource = process.env.REACT_APP_CSV_UPLOAD;

export function* getWalletPaySaga(action) {
    try {
        yield put(actions.walletPayStart());

        const { startIndex, stopIndex, searchFilter } = action;

        const url = baseUrl + getUsergroupWalletPayResource;

        const sessionToken = getSessionToken();
        const email = getUserEmail();
        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionToken,
            },
            params: {
                startIndex,
                stopIndex,
                searchFilter,
                username: email,
            },
        };

        const response = yield axios.get(url, params);
        const data = {
            walletPay: response.data.result.division_list ?? null,
        };

        yield put(actions.walletPaySuccess(data));
    } catch (error) {
        yield put(actions.walletPayFail(error));
    }
}
export function* putRealTimeBatchSaga(action) {
    const url = baseUrl + putRealTimeBatchResource;
    try {
        yield put(actions.realTimeBatchStart());
        const sessionToken = getSessionToken();

        const body = {
            batches: action.data,
        };

        const response = yield axios.put(url, JSON.stringify(body), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionToken,
            },
        });
        yield put(actions.payDetailsDialogStatus(true));
        yield put(actions.realTimeBatchSuccess(response.data.header.otherInfo));
    } catch (error) {
        yield put(actions.payDetailsDialogStatus(false));
        yield call(alertDialogSetSaga, {
            data: {
                open: true,
                severityLevel: 'error',
                message: error.message,
            },
        });
        yield put(actions.realTimeBatchFail(error));
    }
}
export function* postBatchCsvSaga(action) {
    const url = baseUrl + postBatchCsvResource;
    try {
        yield put(actions.postBatchCsvStart());
        const sessionToken = getSessionToken();

        const body = action.data;
        const response = yield axios.post(url, body, {
            headers: {
                Accept: 'application/text',
                'Content-Type': 'application/text',
                Authorization: sessionToken,
            },
        });
        // yield put(actions.payDetailsDialogStatus(true));
        yield call(alertDialogSetSaga, {
            data: {
                open: true,
                severityLevel: 'success',
                message: 'Successfully uploaded the CSV file',
            },
        });
        yield put(actions.postBatchCsvSuccess());
    } catch (error) {
        yield put(actions.payDetailsDialogStatus(false));
        if (error?.response?.data?.errorText) {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.response?.data?.errorText,
                },
            });
        } else {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.message,
                },
            });
        }
        yield put(actions.postBatchCsvFail(error));
    }
}
