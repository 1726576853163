import { put } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions/index';

import { getSessionToken, getUserEmail } from '../../constants/session';

const limit = 200;

export default function* getActivitySaga(action) {
    try {
        yield put(actions.activityStart());

        const { offset } = action;
        const email = getUserEmail();
        const url = null;

        const sessionToken = getSessionToken();

        const response = yield axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionToken,
            },
            params: {
                username: email,
                limit,
                offset,
            },
        });

        const data = {
            activity: response.data.result.activity
                ? response.data.result.activity
                : null,
        };

        yield put(actions.activitySuccess(data));
    } catch (error) {
        yield put(actions.activityFail(error));
    }
}
