// actions/Startup - App initialization
export const STARTUP = 'STARTUP';
export const STARTUP_START = 'STARTUP_START';
export const STARTUP_SUCCESS = 'STARTUP_SUCCESS';
export const STARTUP_FAIL = 'STARTUP_FAIL';

// actions/Auth - Signin
export const AUTH_START = 'AUTH_START';
export const AUTH = 'AUTH'; // actions/Auth - Signin
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_SIGNIN_CHALLENGE = 'AUTH_SIGNIN_CHALLENGE';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_KYC = 'AUTH_KYC';

// Sms challenge
export const CONFIRM_SIGNIN_START = 'CONFIRM_SIGNIN_START';
export const CONFIRM_SIGNIN = 'CONFIRM_SIGNIN';
export const CONFIRM_SIGNIN_SUCCESS = 'CONFIRM_SIGNIN_SUCCESS';
export const CONFIRM_SIGNIN_FAIL = 'CONFIRM_SIGNIN_FAIL';

export const SIGNIN_CHALLENGE_START = 'SIGNIN_CHALLENGE_START';
export const SIGNIN_CHALLENGE_SUCCESS = 'SIGNIN_CHALLENGE_SUCCESS';
export const SIGNIN_CHALLENGE_FAIL = 'SIGNIN_CHALLENGE_FAIL';
export const SIGNIN_CHALLENGE = 'SIGNIN_CHALLENGE';

export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'; // actions/Auth - Forgot password

export const PUT_AVATAR = 'PUT_AVATAR';
export const PUT_AVATAR_START = 'PUT_AVATAR_START';
export const PUT_AVATAR_SUCCESS = 'PUT_AVATAR_SUCCESS';
export const PUT_AVATAR_FAIL = 'PUT_AVATAR_FAIL';

export const DELETE_AVATAR = 'DELETE_AVATAR';
export const DELETE_AVATAR_START = 'DELETE_AVATAR_START';
export const DELETE_AVATAR_SUCCESS = 'DELETE_AVATAR_SUCCESS';
export const DELETE_AVATAR_FAIL = 'DELETE_AVATAR_FAIL';

export const CONFIRM_FORGOT_PASSWORD_START = 'CONFIRM_FORGOT_PASSWORD_START';
export const CONFIRM_FORGOT_PASSWORD_SUCCESS =
    'CONFIRM_FORGOT_PASSWORD_SUCCESS';
export const CONFIRM_FORGOT_PASSWORD_FAIL = 'CONFIRM_FORGOT_PASSWORD_FAIL';
export const CONFIRM_FORGOT_PASSWORD = 'CONFIRM_FORGOT_PASSWORD'; // actions/Auth - Reset password

export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';
export const IMAGE_SUCCESS_DIALOG_CLOSE = 'IMAGE_SUCCESS_DIALOG_CLOSE';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'; // actions/Auth - Update password

export const KYC_START = 'KYC_START';
export const KYC_SUCCESS = 'KYC_SUCCESS';
export const KYC_SUBMISSION = 'KYC_SUBMISSION';
export const KYC_FAIL = 'KYC_FAIL'; // actions/Auth - Kyc submission

export const SIGNOUT_START = 'SIGNOUT_START';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_FAIL = 'SIGNOUT_FAIL';
export const SIGNOUT = 'SIGNOUT';

// actions/Dashboard
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const DASHBOARD_START = 'DASHBOARD_START';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_FAIL = 'DASHBOARD_FAIL';

// actions/Batches
export const GET_BATCHES = 'GET_BATCHES';
export const BATCHES_START = 'BATCHES_START';
export const BATCHES_SUCCESS = 'BATCHES_SUCCESS';
export const BATCHES_FAIL = 'BATCHES_FAIL';
export const BATCH_FILTER_VALUES = 'BATCH_FILTER_VALUES';
export const BATCH_SORT_VALUES = 'BATCH_SORT_VALUES';

// actions/Activity
export const GET_ACTIVITY = 'GET_ACTIVITY';
export const ACTIVITY_START = 'ACTIVITY_START';
export const ACTIVITY_SUCCESS = 'ACTIVITY_SUCCESS';
export const ACTIVITY_FAIL = 'ACTIVITY_FAIL';

// actions/Payments
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const SORT_VALUES = 'SORT_VALUES';
export const TABLE_FILTER = 'TABLE_FILTER';
export const PAYMENTS_START = 'PAYMENTS_START';
export const PAYMENTS_SUCCESS = 'PAYMENTS_SUCCESS';
export const PAYMENTS_FAIL = 'PAYMENTS_FAIL';
// pending payments
export const GET_PENDING_PAYMENTS = 'GET_PENDING_PAYMENTS';
export const PENDING_SORT_VALUES = 'PENDING_SORT_VALUES';
export const PENDING_TABLE_FILTER = 'PENDING_TABLE_FILTER';
export const PENDING_PAYMENTS_START = 'PENDING_PAYMENTS_START';
export const PENDING_PAYMENTS_SUCCESS = 'PENDING_PAYMENTS_SUCCESS';
export const PENDING_PAYMENTS_FAIL = 'PENDING_PAYMENTS_FAIL';

// actions/Summary Report
export const REPORTS_START = 'REPORTS_START';
export const REPORTS_SUCCESS = 'REPORTS_SUCCESS';
export const REPORTS_FAIL = 'REPORTS_FAIL';
export const GET_REPORTS = 'GET_REPORTS';

// actions/AccountSettings
export const POST_ACCOUNT_SETTINGS_START = 'POST_ACCOUNT_SETTINGS_START';
export const POST_ACCOUNT_SETTINGS_SUCCESS = 'POST_ACCOUNT_SETTINGS_SUCCESS';
export const POST_ACCOUNT_SETTINGS_FAIL = 'POST_ACCOUNT_SETTINGS_FAIL';
export const POST_ACCOUNT_SETTINGS = 'POST_ACCOUNT_SETTINGS';

// payment method page
export const GET_PAYMENT_METHODS_START = 'GET_PAYMENT_METHODS_START';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAIL = 'GET_PAYMENT_METHODS_FAIL';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';

export const PUT_PAYMENT_METHOD_START = 'PUT_PAYMENT_METHOD_START';
export const PUT_PAYMENT_METHOD_SUCCESS = 'PUT_PAYMENT_METHOD_SUCCESS';
export const PUT_PAYMENT_METHOD_FAIL = 'PUT_PAYMENT_METHOD_FAIL';
export const PUT_PAYMENT_METHOD = 'PUT_PAYMENT_METHOD';

export const PUT_BANK_INFO_SUBMITTED_START = 'PUT_BANK_INFO_SUBMITTED_START';
export const PUT_BANK_INFO_SUBMITTED_SUCCESS =
    'PUT_BANK_INFO_SUBMITTED_SUCCESS';
export const PUT_BANK_INFO_SUBMITTED_FAIL = 'PUT_BANK_INFO_SUBMITTED_FAIL';
export const PUT_BANK_INFO_SUBMITTED = 'PUT_BANK_INFO_SUBMITTED';

// misc...
export const ALERT_DIALOG_SET_START = 'ALERT_DIALOG_SET_START';
export const ALERT_DIALOG_SET_SUCCESS = 'ALERT_DIALOG_SET_SUCCESS';
export const ALERT_DIALOG_SET_FAIL = 'ALERT_DIALOG_SET_FAIL';
export const ALERT_DIALOG_SET = 'ALERT_DIALOG_SET';

export const ALERT_DIALOG_HIDE_START = 'ALERT_DIALOG_HIDE_START';
export const ALERT_DIALOG_HIDE_SUCCESS = 'ALERT_DIALOG_HIDE_SUCCESS';
export const ALERT_DIALOG_HIDE_FAIL = 'ALERT_DIALOG_HIDE_FAIL';
export const ALERT_DIALOG_HIDE = 'ALERT_DIALOG_HIDE';

export const RESEND_CODE_START = 'RESEND_CODE_START';
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS';
export const RESEND_CODE_FAIL = 'RESEND_CODE_FAIL';
export const RESEND_CODE = 'RESEND_CODE';

export const GET_LANGUAGE_ENGLISH = 'EN';
export const GET_LANGUAGE_SPANISH = 'ES';

export const SHOW = 'SHOW';
export const HIDE = 'HIDE';

// relationships: related suppliers or builders
export const GET_RELATIONSHIPS = 'GET_RELATIONSHIPS';
export const RELATIONSHIPS_START = 'RELATIONSHIPS_START';
export const RELATIONSHIPS_SUCCESS = 'RELATIONSHIPS_SUCCESS';
export const RELATIONSHIPS_FAIL = 'RELATIONSHIPS_FAIL';

// walletPay: related suppliers or builders
export const GET_WALLETPAY = 'GET_WALLETPAY';
export const WALLETPAY_START = 'WALLETPAY_START';
export const WALLETPAY_SUCCESS = 'WALLETPAY_SUCCESS';
export const WALLETPAY_FAIL = 'WALLETPAY_FAIL';

export const PUT_REAL_TIME_BATCH = 'PUT_REAL_TIME_BATCH';
export const REAL_TIME_BATCH_START = 'REAL_TIME_BATCH_START';
export const REAL_TIME_BATCH_SUCCESS = 'REAL_TIME_BATCH_SUCCESS';
export const REAL_TIME_BATCH_FAIL = 'REAL_TIME_BATCH_FAIL';
export const PAY_DETAIL_DIALOG_STATUS = 'PAY_DETAIL_DIALOG_STATUS';
export const POST_BATCH_CSV = 'POST_BATCH_CSV';
export const POST_BATCH_CSV_START = 'POST_BATCH_CSV_START';
export const POST_BATCH_CSV_SUCCESS = 'POST_BATCH_CSV_SUCCESS';
export const POST_BATCH_CSV_FAIL = 'POST_BATCH_CSV_FAIL';

// uploads
export const UPLOAD = 'UPLOAD';
export const UPLOAD_START = 'UPLOAD_START';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAIL = 'UPLOAD_FAIL';

// signup
export const GET_SUPPLIER_SIGNUP_START = 'GET_SUPPLIER_SIGNUP_START';
export const GET_SUPPLIER_SIGNUP_SUCCESS = 'GET_SUPPLIER_SIGNUP_SUCCESS';
export const GET_SUPPLIER_SIGNUP_FAIL = 'GET_SUPPLIER_SIGNUP_FAIL';
export const GET_SUPPLIER_SIGNUP = 'GET_SUPPLIER_SIGNUP';

export const PUT_SUPPLIER_SIGNUP_START = 'PUT_SUPPLIER_SIGNUP_START';
export const PUT_SUPPLIER_SIGNUP_SUCCESS = 'PUT_SUPPLIER_SIGNUP_SUCCESS';
export const PUT_SUPPLIER_SIGNUP_FAIL = 'PUT_SUPPLIER_SIGNUP_FAIL';
export const SUCCESS_POPUP = 'SUCCESS_POPUP';
export const PUT_SUPPLIER_SIGNUP = 'PUT_SUPPLIER_SIGNUP';

// support
export const PUT_USER_SUPPORT_START = 'PUT_USER_SUPPORT_START';
export const PUT_USER_SUPPORT_SUCCESS = 'PUT_USER_SUPPORT_SUCCESS';
export const PUT_USER_SUPPORT_FAIL = 'PUT_USER_SUPPORT_FAIL';
export const PUT_USER_SUPPORT = 'PUT_USER_SUPPORT';
export const CLOSE_USER_SUPPORT_DIALOG = 'CLOSE_USER_SUPPORT_DIALOG';
export const CLOSE_USER_SUPPORT_DIALOG_SUCCESS =
    'CLOSE_USER_SUPPORT_DIALOG_SUCCESS';

// homepage

export const THEME_CHANGE = 'THEME_CHANGE';
export const MENU_OPEN = 'MENU_OPEN';
export const NAVBAR_ACTIVE_CHANGE = 'NAVBAR_ACTIVE_CHANGE';
export const DIALOG_OPEN = 'DIALOG_OPEN';
export const VERIFICATION_DIALOG_OPEN = 'VERIFICATION_DIALOG_OPEN';
export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
// homepagesupport
export const SEND_CONTACTUS_CODE = 'SEND_CONTACTUS_CODE';
export const SEND_MAIL = 'SEND_MAIL';
export const GET_BANNER = 'GET_BANNER';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_START = 'GET_BANNER_START';
export const PUT_HOMEPAGE_SUPPORT_START = 'PUT_HOMEPAGE_SUPPORT_START';
export const PUT_HOMEPAGE_SUPPORT_SUCCESS = 'PUT_HOMEPAGE_SUPPORT_SUCCESS';
export const CLOSE_HOMEPAGE_SUPPORT_DIALOG_SUCCESS =
    'CLOSE_HOMEPAGE_SUPPORT_DIALOG_SUCCESS';
export const PUT_HOMEPAGE_SUPPORT_FAIL = 'PUT_HOMEPAGE_SUPPORT_FAIL';
export const PUT_HOMEPAGE_CODESEND_START = 'PUT_HOMEPAGE_CODESEND_START';
export const PUT_HOMEPAGE_CODESEND_SUCCESS = 'PUT_HOMEPAGE_CODESEND_SUCCESS';
export const CLOSE_HOMEPAGE_CODESEND_DIALOG_SUCCESS =
    'CLOSE_HOMEPAGE_CODESEND_DIALOG_SUCCESS';
export const PUT_HOMEPAGE_CODESEND_FAIL = 'PUT_HOMEPAGE_CODESEND_FAIL';
