import * as actionTypes from './types';

export const sendContactusCode = (data) => ({
    type: actionTypes.SEND_CONTACTUS_CODE,
    data,
});
export const sendMail = (data) => ({
    type: actionTypes.SEND_MAIL,
    data,
});
export const getBanner = () => ({
    type: actionTypes.GET_BANNER,
});
export const getBannerStart = () => ({
    type: actionTypes.GET_BANNER_START,
});
export const getBannerSuccess = (data) => ({
    type: actionTypes.GET_BANNER_SUCCESS,
    data,
});
export const putHomepageSupportStart = () => ({
    type: actionTypes.PUT_HOMEPAGE_SUPPORT_START,
});

export const putHomepageSupportSuccess = (data) => ({
    type: actionTypes.PUT_HOMEPAGE_SUPPORT_SUCCESS,
    data,
});

export const putHomepageSupportFail = (error) => ({
    type: actionTypes.PUT_HOMEPAGE_SUPPORT_FAIL,
    error,
});

export const closeHomepageSupportDialogSuccess = () => ({
    type: actionTypes.CLOSE_HOMEPAGE_SUPPORT_DIALOG_SUCCESS,
});
export const putHomepageCodeSendStart = () => ({
    type: actionTypes.PUT_HOMEPAGE_CODESEND_START,
});

export const putHomepageCodeSendSuccess = (data) => ({
    type: actionTypes.PUT_HOMEPAGE_CODESEND_SUCCESS,
    data,
});

export const putHomepageCodeSendFail = (error) => ({
    type: actionTypes.PUT_HOMEPAGE_CODESEND_FAIL,
    error,
});

export const closeHomepageCodeSendDialogSuccess = () => ({
    type: actionTypes.CLOSE_HOMEPAGE_CODESEND_DIALOG_SUCCESS,
});
