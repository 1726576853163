import { put } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../../actions/index';

import { getSessionToken } from '../../../constants/session';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getUsergroupRelationshipsResource =
    process.env.REACT_APP_USERGROUP_RELATIONSHIPS;

export default function* getRelationshipsSaga(action) {
    try {
        yield put(actions.relationshipsStart());

        const { startIndex, stopIndex, searchFilter } = action;

        const url = baseUrl + getUsergroupRelationshipsResource;

        const sessionToken = getSessionToken();

        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionToken,
            },
            params: {
                startIndex,
                stopIndex,
                searchFilter,
            },
        };

        const response = yield axios.get(url, params);

        const data = {
            relationships: response.data.result.usergroup_list
                ? response.data.result.usergroup_list
                : null,
        };

        yield put(actions.relationshipsSuccess(data));
    } catch (error) {
        yield put(actions.relationshipsFail(error));
    }
}
