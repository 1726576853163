import * as actionTypes from './types';

export const paymentsStart = () => ({
    type: actionTypes.PAYMENTS_START,
});

export const paymentsSuccess = (data) => ({
    type: actionTypes.PAYMENTS_SUCCESS,
    data,
});

export const paymentsFail = (error) => ({
    type: actionTypes.PAYMENTS_FAIL,
    error,
});

export const getPayments = (data) => ({
    type: actionTypes.GET_PAYMENTS,
    data,
});
export const tableFilter = (data) => ({
    type: actionTypes.TABLE_FILTER,
    data,
});
export const tableSortValues = (data) => ({
    type: actionTypes.SORT_VALUES,
    data,
});
