/* eslint-disable no-shadow */
import {
    isArray,
    isEqual,
    transform,
    isObject,
    camelCase,
    snakeCase,
    mapKeys,
} from 'lodash';

import { v4 } from 'uuid';
import moment from 'moment';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { getHistoryData } from './session';

export const initMaterialUiLicense = () =>
    LicenseInfo.setLicenseKey(
        'e3ebce5d9d15ab216a32eb76a6128f45T1JERVI6MjcyMzIsRVhQSVJZPTE2NTgwOTAxMzMwMDAsS0VZVkVSU0lPTj0x',
    );

export const updateObject = (oldObject, updatedProperties) => ({
    ...oldObject,
    ...updatedProperties,
});
// conversion of string to number avoiding $ and , symbols
export const convertDollarToNumber = (dollar) => {
    if (!dollar) return;
    let dotPos = dollar.indexOf('.');
    let commaPos = dollar.indexOf(',');
    let sep = false;

    if (dotPos < 0) dotPos = 0;

    if (commaPos < 0) commaPos = 0;

    if (dotPos > commaPos && dotPos) sep = dotPos;
    else if (commaPos > dotPos && commaPos) sep = commaPos;
    else sep = false;

    if (sep === false) return parseFloat(dollar.replace(/[^\d]/g, ''));

    return parseFloat(
        `${dollar.substr(0, sep).replace(/[^\d]/g, '')}.${dollar
            .substr(sep + 1, dollar.length)
            .replace(/[^0-9]/, '')}`,
    );
};
export const formatUSD = (amount) => {
    /*
     * Formats a numeric value as a string representing the amount in US currency format.
     */
    const amt = `$ ${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    return amt;
};
export const saveStreamCSV = (filename, text) => {
    let blobObject;
    let event;
    if (window.navigator.msSaveBlob) {
        // IE 10 and later, and Edge.
        blobObject = new Blob([text], { type: 'text/csv' });
        window.navigator.msSaveBlob(blobObject, filename);
    } else {
        // Everthing else (except old IE).
        // Create a dummy anchor (with a download attribute) to click.
        const anchor = document.createElement('a');
        anchor.download = filename;
        if (window.URL.createObjectURL) {
            // Everything else new.
            blobObject = new Blob([text], { type: 'text/csv' });
            anchor.href = window.URL.createObjectURL(blobObject);
        } else {
            // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
            // Load up the data into the URI for "download."
            anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
                text,
            )}`;
        }
        if (document.createEvent) {
            event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            anchor.dispatchEvent(event);
        } else {
            anchor.click();
        }
    }
};
export const inputSpaceTrimmer = (value) => {
    let str = value;
    const strLength = str.length;
    if (
        strLength !== 0 &&
        strLength !== 1 &&
        str.charAt(strLength - 1) === ' '
    ) {
        str = str.charAt(strLength - 2) === ' ' ? str.slice(0, -1) : str;
    }
    return str;
};
export const emailValidator = (email) => {
    const validationResponse = {
        isValid: false,
        message: null,
    };
    const pattern =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    validationResponse.isValid = pattern.test(email);
    if (!validationResponse.isValid) {
        validationResponse.message = 'Invalid email address.';
    }

    return validationResponse;
};

export const passwordValidator = (value, rules) => {
    const validationResponse = {
        isValid: true,
        message: null,
    };

    let pattern = null;

    if (rules.RequireSymbols) {
        pattern = /(?=.*[!@#$%^&*])/;
        const hasSymbol = pattern.test(value);
        validationResponse.isValid = hasSymbol && validationResponse.isValid;
        validationResponse.message = !hasSymbol
            ? 'Password must contain at least 1 special character.'
            : validationResponse.message;
    }

    if (rules.RequireNumbers) {
        pattern = /(?=.*[0-9])/;
        const hasNumbers = pattern.test(value);
        validationResponse.isValid = hasNumbers && validationResponse.isValid;
        validationResponse.message = !hasNumbers
            ? 'Password must contain at least 1 numeric value.'
            : validationResponse.message;
    }

    if (rules.RequireLowercase) {
        pattern = /(?=.*[a-z])/;
        const hasLowercase = pattern.test(value);
        validationResponse.isValid = hasLowercase && validationResponse.isValid;
        validationResponse.message = !hasLowercase
            ? 'Password must contain at least 1 lowercase character.'
            : validationResponse.message;
    }

    if (rules.RequireUppercase) {
        pattern = /(?=.*[A-Z])/;
        const hasUppercase = pattern.test(value);
        validationResponse.isValid = hasUppercase && validationResponse.isValid;
        validationResponse.message = !hasUppercase
            ? 'Password must contain at least 1 uppercase character.'
            : validationResponse.message;
    }

    if (value.length < rules.MinimumLength) {
        validationResponse.isValid = false;
        validationResponse.message = `Password must be longer than ${rules.MinimumLength} characters.`;
    }

    return validationResponse;
};

export const passwordCrossValidation = (
    passwordOne,
    passwordTwo,
    compareType,
) => {
    const validationResponse = {
        isValid: true,
        message: null,
    };

    if (compareType === 'reuse') {
        const isReuseViolation =
            passwordOne === passwordTwo || passwordTwo.includes(passwordOne);
        validationResponse.isValid = !isReuseViolation;
        validationResponse.message = isReuseViolation
            ? 'Old password cannot be used or included as part of new password.'
            : null;
    } else if (compareType === 'isMatch') {
        const isConfirmedMatch = passwordOne === passwordTwo;
        validationResponse.isValid = isConfirmedMatch;
        validationResponse.message = !isConfirmedMatch
            ? 'Confirm password must match new password.'
            : null;
    }

    return validationResponse;
};

export const selectInputValidator = (name, value) => {
    const selectInputResponse = {
        isValid: true,
        message: null,
    };

    if (value === '-1') {
        selectInputResponse.isValid = false;
        selectInputResponse.message = `Please select a ${name}.`;
    }

    return selectInputResponse;
};

export const numberLengthValidator = (name, value, length) => {
    const validationResponse = {
        isValid: true,
        message: null,
    };

    if (value.length < length) {
        validationResponse.isValid = false;
        validationResponse.message = `${name} must be at least ${length} numbers.`;
    }
    return validationResponse;
};

export const dobInputValidator = (value, type) => {
    // if under 18 years old return false
    const age = moment().diff(value, 'year');

    if (age >= 18 && type === 'greaterthan') {
        return true;
    }
    if (type === 'lessthan') {
        return moment(value).isBetween('1899-12-31', moment().add(1, 'years'));
    }

    return false;
};

export const isUrlValidator = (value) => {
    const validationResponse = {
        isValid: true,
        message: null,
    };

    const pattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (!pattern.test(value)) {
        validationResponse.isValid = false;
        validationResponse.message = `Please enter a valid website url.`;
    }
    return validationResponse;
};

export const getFileExtension = (fullName) => {
    const index = fullName.lastIndexOf('.');
    const ext = fullName.slice(index);

    return ext;
};

export const phoneValidator = (value) => {
    const validationResponse = {
        isValid: false,
        message: null,
    };
    const pattern = /^\(?([0-9]{3})\)([0-9]{3})[-]?([0-9]{4})$/;
    validationResponse.isValid = pattern.test(value);
    if (!validationResponse.isValid) {
        validationResponse.message = 'Invalid phone number.';
    }

    return validationResponse;
};

// For generating unique keys so persistState doesn't fail on reload.
// ex. https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
export const getUniqueKey = () => v4();

export function dataURItoBlob(dataURI, contentType) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i += 1) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: contentType });
}

export function lodashCompareJson(obj1, obj2) {
    return isEqual(obj1, obj2);
}

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
export function difference(origObj, newObj) {
    function changes(newObj, origObj) {
        let arrayIndexCounter = 0;
        return transform(newObj, (result, value, key) => {
            if (!isEqual(value, origObj[key])) {
                const resultKey = isArray(origObj)
                    ? (arrayIndexCounter += 1)
                    : key;
                result[resultKey] =
                    isObject(value) && isObject(origObj[key])
                        ? changes(value, origObj[key])
                        : value;
            }
        });
    }
    return changes(newObj, origObj);
}

export function updateObjectWithHistory(originalObject, newObject, newKeyName) {
    try {
        const changes = difference(originalObject, newObject);
        const history = getHistoryData();

        const objectWithHistory = {};
        objectWithHistory[newKeyName] = newObject;
        objectWithHistory.history = {
            ...history,
            changes: { type: null, data: changes },
        };

        return objectWithHistory;
    } catch (error) {
        throw new Error(`updateObjectWithHistory ${error}`);
    }
}

export function formatPhoneNumber(str) {
    // Filter only numbers from the input
    const cleaned = `${str}`.replace(/\D/g, '');

    // Check if the input is of correct length
    const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return `(${match[2]}) ${match[3]}-${match[4]}`;
    }

    return null;
}

export function objCamelToSnakeCase(obj) {
    const result = mapKeys(obj, (value, key) => snakeCase(key));
    return result;
}

export function objSnakeToCamelCase(obj) {
    const result = mapKeys(obj, (value, key) => camelCase(key));
    return result;
}

export function maskAccountNumber(str) {
    const result = `${str}`.replace(/\d(?=\d{4})/g, '*');
    return result;
}
export function deepCopy(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(deepCopy);
    }

    const newObj = {};
    Object.entries(obj).forEach(([key, value]) => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            newObj[key] = deepCopy(value);
        }
    });

    return newObj;
}
