import * as actionTypes from './types';

export const activityStart = () => ({
    type: actionTypes.ACTIVITY_START,
});

export const activitySuccess = (data) => ({
    type: actionTypes.ACTIVITY_SUCCESS,
    data,
});

export const activityFail = (error) => ({
    type: actionTypes.ACTIVITY_FAIL,
    error,
});

export const getActivity = (offset) => ({
    type: actionTypes.GET_ACTIVITY,
    offset,
});
