import { put, call } from 'redux-saga/effects';
import axios from 'axios';
import { alertDialogSetSaga } from '../alertDialog';

import { getSessionToken } from '../../../constants/session';

import { getPaymentMethodBody } from '../../../constants/utilities/paymentMethod';

import * as actions from '../../actions/index';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const getDefaultPaymentMethodResource =
    process.env.REACT_APP_VIEWS_DEFAULT_PAYMENT_METHOD;
const putPaymentMethodResource = process.env.REACT_APP_PUT_PAYMENT_METHODS;
const putBankInfoResource = process.env.REACT_APP_PUT_BANK_INFO;

export function* getPaymentMethodsSaga() {
    const url = baseUrl + getDefaultPaymentMethodResource;

    try {
        yield put(actions.getPaymentMethodsStart());

        const sessionToken = getSessionToken();

        const response = yield axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionToken,
            },
        });

        yield put(actions.getPaymentMethodsSuccess(response.data.result));
    } catch (error) {
        yield put(actions.getPaymentMethodsFail(error));
    }
}

export function* putPaymentMethodSaga(action) {
    const url = baseUrl + putPaymentMethodResource;
    try {
        yield put(actions.putPaymentMethodStart());
        const sessionToken = getSessionToken();

        const body = getPaymentMethodBody(action.data);
        yield axios.post(
            url,
            JSON.stringify({
                ...body,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionToken,
                },
            },
        );

        yield put(actions.putPaymentMethodSuccess(action.data));
        yield put(actions.dialogOpen(true));
    } catch (error) {
        yield call(alertDialogSetSaga, {
            data: {
                open: true,
                severityLevel: 'error',
                message: error,
            },
        });
        yield put(actions.putPaymentMethodFail(error));
    }
}

export function* putBankInfoSaga(action) {
    const url = baseUrl + putBankInfoResource;

    try {
        yield put(actions.putBankInfoStart());
        const sessionToken = getSessionToken();

        const body = {
            accountNumber: action.data.accountNumber,
            routingNumber: action.data.routingNumber,
            accountNickname: action.data.accountNickname,
        };

        const response = yield axios.post(url, JSON.stringify(body), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionToken,
            },
        });
        yield call(alertDialogSetSaga, {
            data: {
                open: true,
                severityLevel: 'success',
                message: 'Bank information successfully submitted',
            },
        });
        yield put(actions.putBankInfoSuccess(response.data.result));
    } catch (error) {
        yield call(alertDialogSetSaga, {
            data: {
                open: true,
                severityLevel: 'error',
                message: 'Bank information unsuccessful, please try again',
            },
        });
        yield put(actions.putBankInfoFail(error));
    }
}
