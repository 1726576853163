/* eslint-disable camelcase */
import { put } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions/index';

import { getSessionToken, getUserEmail } from '../../constants/session';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const resource = process.env.REACT_APP_BATCHES;

export default function* getBatchesSaga(action) {
    try {
        yield put(actions.batchesStart());
        const {
            limit,
            csvLimit,
            offset,
            created,
            batch_name,
            batch_amount,
            status,
            min_amount,
            max_amount,
            exact_amount,
            min_count,
            max_count,
            exact_count,
            from_date,
            to_date,
            order_by,
            sort_order,
        } = action.data;
        yield put(actions.batchTableFilter(action.data));

        yield put(
            actions.batchSortValues({
                columnName: order_by ?? '',
                columnOrder: sort_order ?? 'asc',
            }),
        );
        const email = getUserEmail();
        const url = baseUrl + resource;

        const sessionToken = getSessionToken();

        const response = yield axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionToken,
            },
            params: {
                username: email,
                limit: csvLimit ?? limit,
                offset,
                created,
                batch_name,
                batch_amount,
                status,
                min_amount,
                max_amount,
                exact_amount,
                min_count,
                max_count,
                exact_count,
                from_date,
                to_date,
                order_by,
                sort_order,
            },
        });

        const data = {
            batches: response.data.result,
        };
        yield put(actions.batchesSuccess(data));
    } catch (error) {
        yield put(actions.batchesFail(error));
    }
}
