import { snakeCase, mapKeys } from 'lodash';

function getApiParams(obj) {
    // 1. cleanup
    const { agree, stateId, ...params } = obj;

    params.stateId = stateId.id;
    params.countryId = stateId.country_id;
    params.stateAbbreviation = stateId.abbreviation;

    // 2. convert to snakecase for postgres
    const result = mapKeys(params, (value, key) => {
        if (key === 'address1' || key === 'address2') {
            return key;
        }

        return snakeCase(key);
    });

    return result;
}
export default getApiParams;
