import * as actionTypes from './types';

export const startupStart = () => ({
    type: actionTypes.STARTUP_START,
});

export const startupSuccess = (data) => ({
    type: actionTypes.STARTUP_SUCCESS,
    data,
});

export const startupFail = (error) => ({
    type: actionTypes.STARTUP_FAIL,
    error,
});

export const startup = () => ({
    type: actionTypes.STARTUP,
});
