import * as _ from 'lodash';

export function updatePaymentMappingsVisibility(state, action) {
    const { data } = action;
    const {
        lookup,
        payment_methods: paymentMethods,
        payment_methods_mappings: paymentMethodsMappings,
    } = data;

    paymentMethods.forEach((obj) => {
        obj.isVisible = lookup[obj.restrictions];
    });

    // check if all payment methods are the same and assign the payment method id
    const matchingPaymentMethodsMappings = paymentMethodsMappings.filter(
        (item) =>
            Number(item.payment_method_id) ===
            Number(paymentMethodsMappings[0]?.payment_method_id),
    );

    // All payment methods match. All should have payment method Id
    if (
        matchingPaymentMethodsMappings.length === paymentMethodsMappings.length
    ) {
        // add all dropdown value and set a selected
        paymentMethodsMappings.splice(0, 0, {
            id: 0,
            payment_method_id: paymentMethodsMappings[0].payment_method_id,
            payor_id: 0,
            user_group_name: 'All',
            selected: true,
        });
    } else {
        // All is not selected, create all dropdown item
        paymentMethodsMappings.splice(0, 0, {
            id: 0,
            payment_method_id: 0,
            payor_id: 0,
            user_group_name: 'All',
        });
        // set first item to selected item since all is not default
        paymentMethodsMappings[1].selected = true;
    }

    return {
        isLoading: false,
        isComplete: true,
        error: null,
        data: {
            lookup,
            paymentMethodsMappings: [...paymentMethodsMappings],
            paymentMethods: [...paymentMethods],
            showMsg: false,
        },
    };
}

export function updateBankInfoSubmittedVisibility(state, action) {
    let showMsg = false;
    const { lookup } = action.data;
    const { lookup: prevLookup } = state.data;
    const { data } = _.cloneDeep(state);
    const { paymentMethods, paymentMethodsMappings } = data;
    const { rtpEligible } = lookup;
    const rtpMethodId = paymentMethods.filter((item) => item.name === 'RTP')[0]
        .id;
    const wireMethodId = paymentMethods.filter(
        (item) => item.name === 'WIRE',
    )[0].id;
    const newCurrentMethodId = rtpEligible ? rtpMethodId : wireMethodId;
    const selectedId = paymentMethodsMappings.find(
        (obj) => obj.selected,
    ).payment_method_id;
    if (newCurrentMethodId !== selectedId) {
        paymentMethodsMappings.forEach((obj) => {
            obj.payment_method_id = newCurrentMethodId;
        });
    }

    paymentMethods.forEach((obj) => {
        obj.isVisible = lookup[obj.restrictions];
    });

    // check if all payment methods are the same and assign the payment method id
    const matchingPaymentMethodsMappings = paymentMethodsMappings.filter(
        (item) =>
            Number(item.payment_method_id) ===
            Number(paymentMethodsMappings[0]?.payment_method_id),
    );

    // All payment methods match. All should have payment method Id
    if (
        matchingPaymentMethodsMappings.length === paymentMethodsMappings.length
    ) {
        // select all
        paymentMethodsMappings[0].selected = true;
    }

    // show alert, can be expanded to same day ach, etc...
    if (prevLookup.rtpEligible === false && lookup.rtpEligible === true) {
        // show alert, can be expanded to same day ach, etc...
        const currentSelectedId = paymentMethodsMappings.find(
            (obj) => obj.selected,
        ).payment_method_id;
        const currentSelectedName = paymentMethods.find(
            (obj) => obj.id === currentSelectedId,
        ).name;

        if (currentSelectedName !== 'RTP') {
            showMsg = true;
        }
    }

    return {
        isLoading: false,
        isComplete: true,
        error: null,
        data: {
            lookup,
            paymentMethodsMappings: [...paymentMethodsMappings],
            paymentMethods: [...paymentMethods],
            showMsg,
        },
    };
}

export function updatePaymentMappings(statePaymentMappings, newPaymentMapping) {
    const paymentMethodsMappings = _.cloneDeep(statePaymentMappings);
    let paymentMapping = null;

    // clean up old selected item
    paymentMethodsMappings.forEach((item) => delete item.selected);

    // update payment method
    if (newPaymentMapping.id === 0) {
        // update all payment methods if 'all' was selected
        paymentMethodsMappings.map(
            (item) =>
                (item.payment_method_id = newPaymentMapping.payment_method_id),
        );
        paymentMethodsMappings[0].selected = true;
    } else {
        // update single payment method
        paymentMapping = paymentMethodsMappings.find(
            (item) => item.id === newPaymentMapping.id,
        );
        paymentMapping.payment_method_id = newPaymentMapping.payment_method_id;

        // does changing this payment method make them all the same?
        // if so selected ALL
        const individualMappings = paymentMethodsMappings.filter(
            (item) => item.id !== 0,
        );
        const matches = individualMappings.filter(
            (item) =>
                Number(item.payment_method_id) ===
                Number(individualMappings[0]?.payment_method_id),
        );

        // individual payment methods now have the same payment id,
        // so default to ALL
        if (matches.length === individualMappings.length) {
            paymentMethodsMappings[0].payment_method_id =
                paymentMapping.payment_method_id;
            // set all as selected
            paymentMethodsMappings[0].selected = true;
        } else {
            // set all payment method id back to 0
            if (paymentMethodsMappings[0].payment_method_id !== 0) {
                paymentMethodsMappings[0].payment_method_id = 0;
            }

            // update the selected payment mapping
            paymentMapping.selected = true;
        }
    }

    return { paymentMethodsMappings };
}

export function getPaymentMethodBody(data) {
    const { paymentMapping } = data;

    if (paymentMapping.payor_id) {
        return {
            paymentMethod: {
                paymentMethodId: paymentMapping.payment_method_id,
                payorId:
                    paymentMapping.payor_id > 0
                        ? paymentMapping.payor_id
                        : null,
            },
        };
    }

    return {
        paymentMethod: {
            paymentMethodId: paymentMapping.payment_method_id,
        },
    };
}

export function validateRoutingNumber(routingNum) {
    let isValid = false;
    let iterator = 1;
    let total = 0;

    for (const c of routingNum) {
        switch (iterator) {
            case 1:
                total += Number(c) * 3;
                iterator += 1;
                break;
            case 2:
                total += Number(c) * 7;
                iterator += 1;
                break;
            case 3:
                total += Number(c) * 1;
                iterator = 1;
                break;
            default:
            // code block
        }
    }
    isValid = total % 10 === 0;

    return isValid;
}

export function getCurrentPaymentInfoByBuilderId(
    builderId,
    paymentMethodsMappings,
) {
    const selectedPaymentMapping = paymentMethodsMappings.filter(
        (item) => Number(item.payor_id) === Number(builderId),
    );

    return {
        id: selectedPaymentMapping[0]?.id,
        payment_method_id: selectedPaymentMapping[0]?.payment_method_id,
        payor_id: selectedPaymentMapping[0]?.payor_id,
        user_group_name: selectedPaymentMapping[0]?.user_group_name,
    };
}

export function getCurrentPaymentMapping(statePaymentMappings) {
    const paymentMethodsMappings = _.cloneDeep(statePaymentMappings);
    const result = paymentMethodsMappings.filter((item) =>
        Number(item.selected),
    );
    return {
        id: result[0]?.id,
        payment_method_id: result[0]?.payment_method_id,
        payor_id: result[0]?.payor_id,
        user_group_name: result[0]?.user_group_name,
        selected: result[0]?.selected,
    };
}

export function getLinkText(bankInfoSubmitted) {
    return bankInfoSubmitted
        ? 'Update Bank Information'
        : 'Add Bank Information';
}

export function getRtpTitle(bankInfoSubmitted) {
    return bankInfoSubmitted
        ? 'Update Bank Information'
        : 'Enter Bank Information';
}

export function isCheckOrVCC(payments) {
    return payments.some((payment) => {
        const {
            payment_method_name: paymentMethod,
            payment_status_name: paymentStatus,
        } = payment;

        return (
            paymentMethod &&
            paymentStatus &&
            (paymentMethod.toUpperCase() === 'CHECK' ||
                paymentMethod.toUpperCase() === 'VCC') &&
            (paymentStatus.toUpperCase() === 'CREATED' ||
                paymentStatus.toUpperCase() === 'MAILED')
        );
    });
}
