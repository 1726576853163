import { Box, Stack } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './dashboard-common.scss';
import logo from '../../../../static/images/logos/logo-w-vertical.png';
import SwitchIos from '../../../UI/CustomElements/Form/Switch/Switch';
import * as actions from '../../../../store/actions';
import { useLocalStorage } from '../../../../constants/CustomHooks/useLocalStorage';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';
import { loc } from '../../../../constants/strings/local';
import { routePath } from '../../../../constants/strings/RoutePath';

const DashboardHeader = () => {
    const mode = useSelector((state) => state.homepage.mode);
    const { t: trans } = useTranslation();
    const [_, setPreferedTheme] = useLocalStorage('preferedTheme', '');
    const dispatch = useDispatch();
    const changeTheme = () => {
        setPreferedTheme(mode === 'light' ? 'dark' : 'light');
        dispatch(actions.themeChange(mode === 'light' ? 'dark' : 'light'));
    };
    const navigate = useNavigate();
    return (
        <Box className="dashboard-header-container">
            <Box onClick={() => navigate(routePath.homepage)}>
                <img
                    className="dashbord-header-logo"
                    src={logo}
                    alt="hyphen wallet"
                />
            </Box>
            <Box className="dashboard-header-right">
                <ProfileDropdown />
                <Stack className="switch-container-lg">
                    <p>
                        {mode === 'light' ? trans(loc.light) : trans(loc.dark)}
                    </p>
                    <SwitchIos
                        checked={mode === 'dark'}
                        onChange={changeTheme}
                    />
                </Stack>
            </Box>
        </Box>
    );
};

export default DashboardHeader;
