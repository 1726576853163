import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const initialState = {
    isLoading: false,
    isComplete: null,
    error: null,
    data: null,
};

const reportsStart = (state) =>
    updateObject(state, { isLoading: true, isComplete: false, error: null });

const reportsSuccess = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: true,
        error: null,
        data: action.data,
    });

const reportsFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
        error: action.error,
        data: null,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REPORTS_START:
            return reportsStart(state);
        case actionTypes.REPORTS_SUCCESS:
            return reportsSuccess(state, action);
        case actionTypes.REPORTS_FAIL:
            return reportsFail(state, action);
        default:
            return state;
    }
};

export default reducer;
