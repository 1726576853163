import * as actionTypes from '../types';

// get payment methods and default payment id for dropdown
export const getPaymentMethodsStart = () => ({
    type: actionTypes.GET_PAYMENT_METHODS_START,
});

export const getPaymentMethodsSuccess = (data) => ({
    type: actionTypes.GET_PAYMENT_METHODS_SUCCESS,
    data,
});

export const getPaymentMethodsFail = (error) => ({
    type: actionTypes.GET_PAYMENT_METHODS_FAIL,
    error,
});

export const getPaymentMethods = () => ({
    type: actionTypes.GET_PAYMENT_METHODS,
});

// update payment method
export const putPaymentMethodStart = () => ({
    type: actionTypes.PUT_PAYMENT_METHOD_START,
});

export const putPaymentMethodSuccess = (data) => ({
    type: actionTypes.PUT_PAYMENT_METHOD_SUCCESS,
    data,
});

export const putPaymentMethodFail = (error) => ({
    type: actionTypes.PUT_PAYMENT_METHOD_FAIL,
    error,
});

export const putPaymentMethod = (data) => ({
    type: actionTypes.PUT_PAYMENT_METHOD,
    data,
});

// update bank_info_sent
export const putBankInfoStart = () => ({
    type: actionTypes.PUT_BANK_INFO_SUBMITTED_START,
});

export const putBankInfoSuccess = (data) => ({
    type: actionTypes.PUT_BANK_INFO_SUBMITTED_SUCCESS,
    data,
});

export const putBankInfoFail = (error) => ({
    type: actionTypes.PUT_BANK_INFO_SUBMITTED_FAIL,
    error,
});

export const updateBankInfoSubmitted = (data) => ({
    type: actionTypes.PUT_BANK_INFO_SUBMITTED,
    data,
});
