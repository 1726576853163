import { put } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions/index';

import { getSessionToken } from '../../constants/session';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const resource = process.env.REACT_APP_REPORTS_PAYMENT_REMITTANCE;

export default function* getReportsSaga(action) {
    const { paymentId } = action;

    try {
        yield put(actions.reportsStart());

        const url = baseUrl + resource;

        const sessionToken = getSessionToken();

        const response = yield axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionToken,
            },
            params: {
                paymentId,
            },
        });

        const data = response.data.result ? response.data.result : null;

        yield put(actions.reportsSuccess(data));
    } catch (error) {
        yield put(actions.reportsFail(error));
    }
}
