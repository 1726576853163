import * as actionTypes from '../types';

export const relationshipsStart = () => ({
    type: actionTypes.RELATIONSHIPS_START,
});

export const relationshipsSuccess = (data) => ({
    type: actionTypes.RELATIONSHIPS_SUCCESS,
    data,
});

export const relationshipsFail = (error) => ({
    type: actionTypes.RELATIONSHIPS_FAIL,
    error,
});

export const getRelationships = (startIndex, stopIndex, searchFilter) => ({
    type: actionTypes.GET_RELATIONSHIPS,
    startIndex,
    stopIndex,
    searchFilter,
});
