import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const englishLogo = require('../../static/images/welcome.jpg');
const spanishLogo = require('../../static/images/welcome-spanish.png');

const images = { englishLogo, spanishLogo };

const initialState = {
    loginImage: images.englishLogo,
};

const setEnglishImage = (state) =>
    updateObject(state, {
        loginImage: images.englishLogo,
    });

const setSpanishImage = (state) =>
    updateObject(state, {
        loginImage: images.spanishLogo,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LANGUAGE_SPANISH:
            return setSpanishImage(state);
        case actionTypes.GET_LANGUAGE_ENGLISH:
            return setEnglishImage(state);
        default:
            return state;
    }
};

export default reducer;
