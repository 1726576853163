import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';

export function* alertDialogSetSaga(action) {
    const { data } = action;

    try {
        yield put(actions.alertDialogSetStart());
        yield put(actions.alertDialogSetSuccess(data));
    } catch (error) {
        yield put(actions.alertDialogSetFail(error));
    }
}

export function* alertDialogHideSaga(action) {
    const { data } = action;

    try {
        yield put(actions.alertDialogHideStart());
        yield put(actions.alertDialogHideSuccess(data));
    } catch (error) {
        yield put(actions.alertDialogHideFail(error));
    }
}
