import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from '../../store/actions';

// Any intialization, startup, run once stuff should go here...
function Startup() {
    const dispatch = useDispatch();

    // if (!process.env.REACT_APP_API_BASE_URL) {
    //     dotenv.config({
    //         path: path.resolve(
    //             __dirname,
    //             `../../../.env.${process.env.ENVIRONMENT}`
    //         ),
    //     });
    // }

    // ComponentDidMount equivalent, but more stable
    useEffect(() => {
        dispatch(actions.startup());
    }, [dispatch]);

    return null;
}

export default Startup;
