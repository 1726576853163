/* eslint-disable global-require */
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '@fontsource/roboto';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CssBaseline from '@mui/material/CssBaseline';

import { initMaterialUiLicense } from './constants/utility';
import './static/css/style.scss';

import Startup from './hoc/Startup/Startup';
import MainRouter from './hoc/Routes/MainRouter';
import { appTheme } from './constants/theme';
import { useLocalStorage } from './constants/CustomHooks/useLocalStorage';
import * as actions from './store/actions';
import DashboardLayout from './components/WalletDashBoard/DashboardLayout/DashboardLayout';

const App = () => {
    const [preferedTheme] = useLocalStorage('preferedTheme', '');
    const { i18n, t: trans } = useTranslation();
    const [preferedLanguage] = useLocalStorage('preferedLanguage', '');
    const [theme, setTheme] = useState(
        createTheme(appTheme(preferedTheme === 'light' ? 'light' : 'dark')),
    );
    const dispatch = useDispatch();
    const mode = useSelector((state) => state.homepage.mode);
    const alert = useSelector((state) => state.alertDialog);

    useEffect(() => {
        setTheme(createTheme(appTheme(mode)));
    }, [mode]);
    useEffect(() => {
        if (preferedTheme) dispatch(actions.themeChange(preferedTheme));
    }, [preferedTheme]);
    useEffect(() => {
        if (preferedLanguage) i18n.changeLanguage(preferedLanguage);
    }, [preferedLanguage]);
    useEffect(() => {
        let timeOut;
        if (alert.open) {
            timeOut = setTimeout(() => {
                dispatch(actions.alertDialogHideStart());
            }, 15000);
        }
        return () => clearTimeout(timeOut);
    }, [alert.open]);

    initMaterialUiLicense();

    let msg = null;
    if (
        typeof alert?.message === 'string' ||
        alert?.message instanceof String
    ) {
        msg = `${trans(alert.message)}`;
    } else if (alert?.message) {
        const { key, deliveryMedium, destination } = alert?.message;
        msg = `${trans(key, { deliveryMedium, destination })}`;
    }
    useEffect(() => {
        document.body.classList.toggle('hw_dark_mode', mode === 'dark');
        document.body.classList.toggle('hw_light_mode', mode !== 'dark');
    }, [mode]);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Startup />
            {alert.open && (
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={alert.open}
                >
                    <Alert
                        onClose={() => dispatch(actions.alertDialogHideStart())}
                        severity={alert.severityLevel}
                        sx={{ width: '35ch' }}
                    >
                        {msg}
                    </Alert>
                </Snackbar>
            )}
            <Router>
                <DashboardLayout>
                    <MainRouter />
                </DashboardLayout>
            </Router>
        </ThemeProvider>
    );
};

export default App;
