import * as actionTypes from './types';

export const getSupplierSignupStart = () => ({
    type: actionTypes.GET_SUPPLIER_SIGNUP_START,
});

export const getSupplierSignupSuccess = (data) => ({
    type: actionTypes.GET_SUPPLIER_SIGNUP_SUCCESS,
    data,
});

export const getSupplierSignupFail = (error) => ({
    type: actionTypes.GET_SUPPLIER_SIGNUP_FAIL,
    error,
});

export const getSupplierSignup = () => ({
    type: actionTypes.GET_SUPPLIER_SIGNUP,
});

export const putSupplierSignupStart = () => ({
    type: actionTypes.PUT_SUPPLIER_SIGNUP_START,
});

export const putSupplierSignupSuccess = (data) => ({
    type: actionTypes.PUT_SUPPLIER_SIGNUP_SUCCESS,
    data,
});
export const successPopupStatusChange = (data) => ({
    type: actionTypes.SUCCESS_POPUP,
    data,
});

export const putSupplierSignupFail = (error) => ({
    type: actionTypes.PUT_SUPPLIER_SIGNUP_FAIL,
    error,
});

export const putSupplierSignup = (data) => ({
    type: actionTypes.PUT_SUPPLIER_SIGNUP,
    data,
});
