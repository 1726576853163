import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const initialState = {
    isLoading: false,
    isComplete: null,
    error: null,
    data: null,
    columnName: '',
    columnOrder: 'asc',
    filter: {},
};

const batchesStart = (state) =>
    updateObject(state, {
        isLoading: true,
        isComplete: false,
        error: null,
        columnName: '',
        columnOrder: 'asc',
    });

const batchesSuccess = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: true,
        error: null,
        data: action.data.batches,
    });

const batchesFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
        error: action.error,
        data: null,
    });
const batchSortValues = (state, action) =>
    updateObject(state, {
        columnOrder: action.data.columnOrder,
        columnName: action.data.columnName,
    });
const batchTableFilter = (state, action) =>
    updateObject(state, {
        filter: action.data,
    });
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BATCHES_START:
            return batchesStart(state);
        case actionTypes.BATCHES_SUCCESS:
            return batchesSuccess(state, action);
        case actionTypes.BATCHES_FAIL:
            return batchesFail(state, action);
        case actionTypes.BATCH_SORT_VALUES:
            return batchSortValues(state, action);
        case actionTypes.BATCH_FILTER_VALUES:
            return batchTableFilter(state, action);
        default:
            return state;
    }
};

export default reducer;
