import * as actionTypes from './types';

export const alertDialogSetStart = () => ({
    type: actionTypes.ALERT_DIALOG_SET_START,
});

export const alertDialogSetSuccess = (data) => ({
    type: actionTypes.ALERT_DIALOG_SET_SUCCESS,
    data,
});

export const alertDialogSetFail = (error) => ({
    type: actionTypes.ALERT_DIALOG_SET_FAIL,
    error,
});
export const alertDialogSet = (data) => ({
    type: actionTypes.ALERT_DIALOG_SET,
    data,
});

export const alertDialogHideStart = () => ({
    type: actionTypes.ALERT_DIALOG_HIDE_START,
});

export const alertDialogHideSuccess = (data) => ({
    type: actionTypes.ALERT_DIALOG_HIDE_SUCCESS,
    data,
});

export const alertDialogHideFail = (error) => ({
    type: actionTypes.ALERT_DIALOG_HIDE_FAIL,
    error,
});
export const alertDialogHide = (data) => ({
    type: actionTypes.ALERT_DIALOG_HIDE,
    data,
});
