import { call, put } from 'redux-saga/effects';

import axios from 'axios';
import Cookie from 'js-cookie';
import {
    getCognitoGroup,
    getSessionToken,
    getUserEmail,
    signinCleanUp,
} from '../../constants/session';

import { alertDialogSetSaga } from './alertDialog';

import * as actions from '../actions/index';
import { routePath } from '../../constants/strings/RoutePath';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const signinResource = process.env.REACT_APP_ACCOUNT_SIGNIN;
const forgotPasswordResource = process.env.REACT_APP_ACCOUNT_FORGOT_PASSWORD;
const confirmForgotPasswordResource =
    process.env.REACT_APP_ACCOUNT_CONFIRM_FORGOT_PASSWORD;
const kycResource = process.env.REACT_APP_POST_KYC_SUBMISSION;
const smsChallenge = process.env.REACT_APP_ACCOUNT_SMS_CHALLENGE;
const completeNewPasswordResource =
    process.env.REACT_APP_ACCOUNT_COMPLETE_NEW_PASSWORD;
const deleteAvatarResource = process.env.REACT_APP_ACCOUNT_DELETE_AVATAR;
const uploadAvatarResource = process.env.REACT_APP_ACCOUNT_UPLOAD_AVATAR;

export function* signinSaga(action) {
    const { email, password, navigate } = action.data;
    const url = baseUrl + signinResource;

    try {
        yield put(actions.authStart());
        let data = JSON.stringify({
            username: email,
            password,
        });

        const signinResponse = yield axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        data = {
            ...signinResponse.data.result,
        };
        localStorage.setItem('login_time', Date.now());
        if (data.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
            yield put(actions.authSigninChallenge());
            navigate(routePath.updatepassword);
        } else if (data.ChallengeName === 'SMS_MFA') {
            yield put(actions.authSigninChallenge(data));
            navigate(routePath.codechallenge, {
                state: {
                    mode: 'codeChallenge',
                    message: {
                        severityLevel: 'success',
                        text: `Password successfully updated.`,
                    },
                    CODE_DELIVERY_DELIVERY_MEDIUM:
                        signinResponse.challengeParam
                            .CODE_DELIVERY_DELIVERY_MEDIUM,
                    CODE_DELIVERY_DESTINATION:
                        signinResponse.challengeParam.CODE_DELIVERY_DESTINATION,
                },
            });
        } else if (
            (data?.WalletUserSettings?.isUserKYCSubmitted === false ||
                data?.WalletUserSettings?.isKYCSubmitted === false) &&
            (data?.WalletUserSettings?.isUserKYCApproved === false ||
                data?.WalletUserSettings?.isKYCApproved === false)
        ) {
            yield put(actions.authKyc());
            navigate(routePath.kycSubmission, {
                state: {
                    userDetails: data.WalletUserSettings,
                    authData: data,
                },
            });
        } else {
            const cognitoGroup = getCognitoGroup();

            yield put(actions.authSuccess(data));
            if (cognitoGroup === 'hyphen_admin') {
                navigate('/admin/user');
            } else {
                navigate(routePath.dashboard);
            }
        }
    } catch (error) {
        if (error.response && error.response.data) {
            // TODO: need to find out the response message that is returned from https://api-wallet-dev.hyphensolutions.com/account/signin

            let message = '';
            if (
                error.response.data.error === 'Incorrect username or password.'
            ) {
                message = 'toaster-login-incorrect-credentials';
                yield call(alertDialogSetSaga, {
                    data: {
                        open: true,
                        severityLevel: 'error',
                        message,
                    },
                });
            } else {
                yield call(alertDialogSetSaga, {
                    data: {
                        open: true,
                        severityLevel: 'error',
                        message: error?.response?.data?.error,
                    },
                });
            }
            yield put(actions.authFail(message));
        } else {
            // TODO: need to figure out how to translate this error message
            yield put(actions.authFail(error?.response?.data?.error));
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error.message,
                },
            });
        }
    }
}

export function* confirmSigninSaga(action) {
    const { confirmationCode, navigate } = action.data;
    const username = Cookie.get('username') ? Cookie.get('username') : null;
    const session = Cookie.get('token') ? Cookie.get('token') : null;
    const url = baseUrl + smsChallenge;

    try {
        yield put(actions.authStart());

        const data = JSON.stringify({
            username,
            confirmationCode,
            session,
        });

        yield axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        Cookie.remove('username');
        Cookie.remove('token');

        const accountSettings = null;

        if (!accountSettings) {
            throw new Error('No account settings found for this user.');
        }

        yield put(actions.authSuccess());

        if (accountSettings === 'hyphen_admin') {
            navigate('/admin/user');
        } else {
            navigate(routePath.dashboard);
        }
    } catch (error) {
        if (error.response && error.response.data) {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.response?.data?.error,
                },
            });
        } else {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.message,
                },
            });
        }
        yield put(actions.authFail(error?.response?.data?.error));
    }
}

export function* signoutSaga() {
    try {
        yield put(actions.signoutStart());

        // clear storage
        signinCleanUp();

        yield put(actions.signoutSuccess());
    } catch (error) {
        if (error.response && error.response.data) {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.response?.data?.error,
                },
            });
        } else {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.message,
                },
            });
        }
        yield put(actions.signoutFail(error?.response?.data?.error));
    }
}

export function* kycSaga(action) {
    const url = baseUrl + kycResource;
    const { submitData, navigate, authData } = action.data;

    try {
        yield put(actions.kycStart());
        const body = {
            data: { ...submitData },
        };
        yield axios.post(
            url,
            JSON.stringify({
                ...body,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authData?.WalletCognitoUserSession?.IdToken}`,
                },
            },
        );
        yield put(actions.authSuccess(authData));
        navigate(routePath.dashboard, { replace: true });
        yield call(alertDialogSetSaga, {
            data: {
                open: true,
                severityLevel: 'success',
                message: 'toaster-kyc-success',
            },
        });
    } catch (error) {
        yield put(actions.authSuccess(authData));
        navigate(routePath.support, { replace: true });
        yield call(alertDialogSetSaga, {
            data: {
                open: true,
                severityLevel: 'error',
                message: 'toaster-kyc-error',
            },
        });
    }
}

export function* confirmForgotPassword(action) {
    const { navigate, code, newPassword, setValues } = action.data;
    const username = Cookie.get('username') ? Cookie.get('username') : null;
    const url = baseUrl + confirmForgotPasswordResource;

    try {
        yield put(actions.confirmForgotPasswordStart());
        const data = JSON.stringify({
            username,
            confirmationCode: code,
            password: newPassword,
        });

        yield axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        navigate(routePath.login);

        yield put(actions.confirmForgotPasswordSuccess());

        yield call(alertDialogSetSaga, {
            data: {
                open: true,
                severityLevel: 'success',
                message: `toaster-pass-change-success`,
            },
        });

        navigate(routePath.login);
    } catch (error) {
        setValues({
            code: '',
            newPassword,
            confirmPassword: newPassword,
        });
        if (
            error?.response?.data?.error ===
            'LimitExceededException: Attempt limit exceeded, please try after some time.'
        ) {
            navigate(routePath.login);
        } else {
            navigate(routePath.confirmforgotpassword, {
                state: { mode: 'reload' },
            });
        }
        if (error.response && error.response.data) {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.response?.data?.error,
                },
            });
        } else {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.message,
                },
            });
        }
        navigate(routePath.confirmforgotpassword, {
            state: { mode: 'reload' },
        });
        yield put(
            actions.confirmForgotPasswordFail(error?.response?.data?.error),
        );
    }
}

export function* forgotPasswordSaga(action) {
    const { email, navigate } = action.data;
    const url = baseUrl + forgotPasswordResource;
    try {
        yield put(actions.forgotPasswordStart());

        let data = JSON.stringify({
            username: email,
        });

        const forgotPasswordResponse = yield axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        data = {
            username: email,
            ...forgotPasswordResponse.data.result,
        };

        yield put(actions.forgotPasswordSuccess(data));
        navigate(routePath.confirmforgotpassword);
        yield call(alertDialogSetSaga, {
            data: {
                open: true,
                severityLevel: 'info',
                message: {
                    key: 'toaster-send-code-msg',
                    deliveryMedium: `${data.DeliveryMedium}`,
                    destination: `${data.Destination}`,
                },
            },
        });
    } catch (error) {
        if (error.response && error.response.data) {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.response?.data?.error,
                },
            });
        } else {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.message,
                },
            });
        }
        yield put(actions.forgotPasswordFail(error?.response?.data?.error));
        navigate(routePath.forgotpassword);
    }
}

export function* resendCodeSaga(action) {
    const { navigate } = action.data;

    const url = baseUrl + forgotPasswordResource;

    try {
        yield put(actions.resendCodeStart());

        const username = Cookie.get('username') ? Cookie.get('username') : null;
        let data = JSON.stringify({
            username,
        });

        const resendCodeResponse = yield axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        data = {
            username,
            ...resendCodeResponse.data.result,
        };
        navigate(routePath.confirmforgotpassword);
        yield put(actions.resendCodeSuccess(data));
        yield call(alertDialogSetSaga, {
            data: {
                open: true,
                severityLevel: 'info',
                message: {
                    key: 'toaster-send-code-msg',
                    deliveryMedium: `${data.DeliveryMedium}`,
                    destination: `${data.Destination}`,
                },
            },
        });
    } catch (error) {
        if (
            error?.response?.data?.error ===
            'LimitExceededException: Attempt limit exceeded, please try after some time.'
        ) {
            navigate(routePath.login);
        }
        if (error.response && error.response.data) {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.response?.data?.error,
                },
            });
        } else {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.message,
                },
            });
        }
        yield put(actions.resendCodeFail(error?.response?.data?.error));
    }
}

export function* updatePasswordSaga(action) {
    const { email, password, newPassword, navigate, setValues } = action.data;
    const url = baseUrl + completeNewPasswordResource;
    try {
        yield put(actions.updatePasswordStart());
        const data = JSON.stringify({
            email,
            password,
            newPassword,
        });

        yield axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        navigate(routePath.login);
        yield call(alertDialogSetSaga, {
            data: {
                open: true,
                severityLevel: 'success',
                message: 'toaster-success-update-password',
            },
        });
        yield put(actions.updatePasswordSuccess());
    } catch (error) {
        navigate(routePath.updatepassword);
        setValues({
            newPassword,
            confirmPassword: newPassword,
            email: '',
            password: '',
        });
        if (error.response && error.response.data) {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.response?.data?.error,
                },
            });
        } else {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.message,
                },
            });
        }
        yield put(actions.updatePasswordFail(error?.response?.data?.error));
    }
}

export function* deleteAvatarSaga(action) {
    try {
        yield put(actions.deleteAvatarStart());

        const { filename } = action;

        const url = baseUrl + deleteAvatarResource;

        const token = getSessionToken();
        const username = getUserEmail();

        const header = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        };

        const data = {
            filename,
            username,
        };

        yield axios.delete(url, {
            header,
            data,
        });

        yield put(actions.deleteAvatarSuccess());
    } catch (error) {
        if (error.response && error.response.data) {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.response?.data?.error,
                },
            });
        } else {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.message,
                },
            });
        }
        yield put(actions.deleteAvatarFail(error?.response?.data?.error));
    }
}

export function* putAvatarSaga(action) {
    try {
        yield put(actions.putAvatarStart());

        const { file, filename } = action;
        const url = baseUrl + uploadAvatarResource;

        const putPresignToken = getSessionToken();
        const username = getUserEmail();

        const putHeader = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: putPresignToken,
            },
        };

        const data = { username, filename, file };

        // upload avatar
        const putResponse = yield axios.post(url, data, putHeader);
        const avatar = putResponse?.data?.result;

        yield put(actions.putAvatarSuccess(avatar));
    } catch (error) {
        if (error.response && error.response.data) {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.response?.data?.error,
                },
            });
        } else {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.message,
                },
            });
        }
        yield put(actions.putAvatarFail(error?.response?.data?.error));
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }
}
