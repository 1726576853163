import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { get } from 'lodash';

const ImageModal = (props) => {
    const open = get(props, 'open', false);
    const image = get(props, 'image', '');
    const closeHandler = get(props, 'closeHandler', () => {});

    return (
        <Dialog open={open} onClose={closeHandler} maxWidth="xl">
            <DialogContent>
                <img
                    src={image}
                    alt="Modal"
                    style={{ maxWidth: '70vw', maxHeight: '80vh' }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default ImageModal;
