import * as actionTypes from './types';

export const pendingPaymentsStart = () => ({
    type: actionTypes.PENDING_PAYMENTS_START,
});

export const pendingPaymentsSuccess = (data) => ({
    type: actionTypes.PENDING_PAYMENTS_SUCCESS,
    data,
});

export const pendingPaymentsFail = (error) => ({
    type: actionTypes.PENDING_PAYMENTS_FAIL,
    error,
});

export const getPendingPayments = (data) => ({
    type: actionTypes.GET_PENDING_PAYMENTS,
    data,
});
export const pendingTableFilter = (data) => ({
    type: actionTypes.PENDING_TABLE_FILTER,
    data,
});
export const pendingTableSortValues = (data) => ({
    type: actionTypes.PENDING_SORT_VALUES,
    data,
});
