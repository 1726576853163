import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import throttle from 'lodash/throttle';
import { loadState, saveState } from '../constants/localStorage-helper';

// combine reducers
import startupReducer from './reducers/startup';
import authReducer from './reducers/auth';
import alertDialogReducer from './reducers/alertDialog';
import dashboardReducer from './reducers/dashboard';
import reportsReducer from './reducers/reports';
import activityReducer from './reducers/activity';
import batchesReducer from './reducers/batches';
import paymentsReducer from './reducers/payments';
import pendingPaymentsReducer from './reducers/pendingPayments';
import homepageSupportReducer from './reducers/homepageSupport';
import paymentMethodReducer from './reducers/admin/paymentMethod';
import relationshipsReducer from './reducers/admin/relationships';
import walletPayReducer from './reducers/admin/walletPay';
import supplierSignupReducer from './reducers/supplierSignup';
import userSupportReducer from './reducers/userSupport';
import toggleImagesReducer from './reducers/toggleImages';
import toggleLanguageReducer from './reducers/languageSelection';
import toggleThemeReducer from './reducers/homepage';

// import sagas
import {
    watchStartup,
    watchAuth,
    watchAlertDialog,
    watchDashboard,
    watchReports,
    watchActivity,
    watchBatches,
    watchPayments,
    watchPendingPayments,
    watchPaymentMethod,
    watchRelationships,
    watchWalletPay,
    watchSupplierSignup,
    watchUserSupport,
    watchHomepageSupport,
} from './sagas';

const rootReducer = combineReducers({
    startup: startupReducer,
    auth: authReducer,
    alertDialog: alertDialogReducer,
    dashboard: dashboardReducer,
    reports: reportsReducer,
    activity: activityReducer,
    batches: batchesReducer,
    payments: paymentsReducer,
    pendingPayments: pendingPaymentsReducer,
    paymentMethod: paymentMethodReducer,
    relationships: relationshipsReducer,
    walletPay: walletPayReducer,
    signup: supplierSignupReducer,
    userSupport: userSupportReducer,
    toggleImages: toggleImagesReducer,
    languageSelection: toggleLanguageReducer,
    homepage: toggleThemeReducer,
    homepageSupport: homepageSupportReducer,
});

const sagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedState = loadState();

const store = createStore(
    rootReducer,
    persistedState,
    storeEnhancers(applyMiddleware(sagaMiddleware)),
);

store.subscribe(
    throttle(() => {
        // Modify this to only save what we want to persist
        // like below:
        saveState({
            auth: store.getState().auth,
        });
    }),
);

// run sagas
sagaMiddleware.run(watchStartup);
sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchAlertDialog);
sagaMiddleware.run(watchDashboard);
sagaMiddleware.run(watchReports);
sagaMiddleware.run(watchActivity);
sagaMiddleware.run(watchBatches);
sagaMiddleware.run(watchPayments);
sagaMiddleware.run(watchPendingPayments);
sagaMiddleware.run(watchPaymentMethod);
sagaMiddleware.run(watchRelationships);
sagaMiddleware.run(watchWalletPay);
sagaMiddleware.run(watchSupplierSignup);
sagaMiddleware.run(watchUserSupport);
sagaMiddleware.run(watchHomepageSupport);

export default store;
