import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/types';

import startupSaga from './startup';

import {
    confirmForgotPassword,
    confirmSigninSaga,
    deleteAvatarSaga,
    forgotPasswordSaga,
    putAvatarSaga,
    resendCodeSaga,
    signinSaga,
    signoutSaga,
    kycSaga,
    updatePasswordSaga,
} from './auth';
import { alertDialogHideSaga, alertDialogSetSaga } from './alertDialog';
import getDashboardSaga from './dashboard';
import getReportsSaga from './reports';
import getActivitySaga from './activity';
import getBatchesSaga from './batches';
import getPaymentsSaga from './payments';
import getPendingPaymentsSaga from './pendingPayments';

import {
    getPaymentMethodsSaga,
    putPaymentMethodSaga,
    putBankInfoSaga,
} from './admin/paymentMethod';

import {
    getWalletPaySaga,
    putRealTimeBatchSaga,
    postBatchCsvSaga,
} from './admin/walletPay';
import getRelationshipsSaga from './admin/relationships';
import { getSupplierSignupSaga, putSupplierSignupSaga } from './supplierSignup';
import { closeUserSupportDialogSaga, putUserSupportSaga } from './userSupport';
import {
    putHomepageSupportSaga,
    getHomepageBanner,
    putHomepageSendCodeSaga,
} from './homepageContactus';

export function* watchStartup() {
    yield takeEvery(actionTypes.STARTUP, startupSaga);
}

export function* watchAuth() {
    yield all([
        takeEvery(actionTypes.CONFIRM_FORGOT_PASSWORD, confirmForgotPassword),
        takeEvery(actionTypes.CONFIRM_SIGNIN, confirmSigninSaga),
        takeEvery(actionTypes.DELETE_AVATAR, deleteAvatarSaga),
        takeEvery(actionTypes.FORGOT_PASSWORD, forgotPasswordSaga),
        takeEvery(actionTypes.PUT_AVATAR, putAvatarSaga),
        takeEvery(actionTypes.RESEND_CODE, resendCodeSaga),
        takeEvery(actionTypes.AUTH, signinSaga),
        takeEvery(actionTypes.SIGNOUT, signoutSaga),
        takeEvery(actionTypes.KYC_SUBMISSION, kycSaga),
        takeEvery(actionTypes.UPDATE_PASSWORD, updatePasswordSaga),
    ]);
}

export function* watchAlertDialog() {
    yield all([
        takeEvery(actionTypes.ALERT_DIALOG_HIDE, alertDialogHideSaga),
        takeEvery(actionTypes.ALERT_DIALOG_SET, alertDialogSetSaga),
    ]);
}

export function* watchDashboard() {
    yield takeEvery(actionTypes.GET_DASHBOARD, getDashboardSaga);
}

export function* watchReports() {
    yield takeEvery(actionTypes.GET_REPORTS, getReportsSaga);
}

export function* watchActivity() {
    yield takeEvery(actionTypes.GET_ACTIVITY, getActivitySaga);
}

export function* watchBatches() {
    yield takeEvery(actionTypes.GET_BATCHES, getBatchesSaga);
}

export function* watchPayments() {
    yield takeEvery(actionTypes.GET_PAYMENTS, getPaymentsSaga);
}
export function* watchPendingPayments() {
    yield takeEvery(actionTypes.GET_PENDING_PAYMENTS, getPendingPaymentsSaga);
}

export function* watchPaymentMethod() {
    yield all([
        takeEvery(actionTypes.GET_PAYMENT_METHODS, getPaymentMethodsSaga),
        takeEvery(actionTypes.PUT_PAYMENT_METHOD, putPaymentMethodSaga),
        takeEvery(actionTypes.PUT_BANK_INFO_SUBMITTED, putBankInfoSaga),
    ]);
}

export function* watchRelationships() {
    yield takeEvery(actionTypes.GET_RELATIONSHIPS, getRelationshipsSaga);
}
export function* watchWalletPay() {
    yield all([
        takeEvery(actionTypes.GET_WALLETPAY, getWalletPaySaga),
        takeEvery(actionTypes.PUT_REAL_TIME_BATCH, putRealTimeBatchSaga),
        takeEvery(actionTypes.POST_BATCH_CSV, postBatchCsvSaga),
    ]);
}

export function* watchSupplierSignup() {
    yield all([
        takeEvery(actionTypes.GET_SUPPLIER_SIGNUP, getSupplierSignupSaga),
        takeEvery(actionTypes.PUT_SUPPLIER_SIGNUP, putSupplierSignupSaga),
    ]);
}

export function* watchUserSupport() {
    yield all([
        takeEvery(actionTypes.PUT_USER_SUPPORT, putUserSupportSaga),
        takeEvery(
            actionTypes.CLOSE_USER_SUPPORT_DIALOG,
            closeUserSupportDialogSaga,
        ),
    ]);
}
export function* watchHomepageSupport() {
    yield all([
        takeEvery(actionTypes.SEND_MAIL, putHomepageSupportSaga),
        takeEvery(actionTypes.GET_BANNER, getHomepageBanner),
        takeEvery(actionTypes.SEND_CONTACTUS_CODE, putHomepageSendCodeSaga),
    ]);
}
