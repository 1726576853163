import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const initialState = {
    isLoading: false,
    isComplete: null,
    error: null,
    data: null,
    columnName: '',
    columnOrder: 'asc',
    filter: {},
};

const pendingPaymentsStart = (state) =>
    updateObject(state, {
        isLoading: true,
        isComplete: false,
        error: null,
        columnName: '',
        columnOrder: 'asc',
    });

const pendingPaymentsSuccess = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: true,
        error: null,
        data: action.data.pendingPayments,
    });

const pendingPaymentsFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
        error: action.error,
        data: null,
    });

const pendingTableSortValues = (state, action) =>
    updateObject(state, {
        columnOrder: action.data.columnOrder,
        columnName: action.data.columnName,
    });
const pendingTableFilter = (state, action) =>
    updateObject(state, {
        filter: action.data,
    });
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PENDING_PAYMENTS_START:
            return pendingPaymentsStart(state);
        case actionTypes.PENDING_PAYMENTS_SUCCESS:
            return pendingPaymentsSuccess(state, action);
        case actionTypes.PENDING_PAYMENTS_FAIL:
            return pendingPaymentsFail(state, action);
        case actionTypes.PENDING_SORT_VALUES:
            return pendingTableSortValues(state, action);
        case actionTypes.PENDING_TABLE_FILTER:
            return pendingTableFilter(state, action);
        default:
            return state;
    }
};

export default reducer;
