import React from 'react';
import Grid from '@mui/material/Grid';

import SectionHeader from '../Common/SectionHeader';

import builderImage from '../../../static/images/BuilderFAQ.jpg';
import './builder-faq.scss';

const BuilderFAQ = () => (
    <Grid
        container
        backgroundColor="dark.main"
        className="info-card-container-dashboard"
    >
        <Grid item xs={12}>
            <SectionHeader title="FAQ" />
            <img className="faq-img" src={builderImage} alt="" />
        </Grid>
    </Grid>
);

export default BuilderFAQ;
