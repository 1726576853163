import * as actionTypes from './types';

export const themeChange = (data) => ({
    type: actionTypes.THEME_CHANGE,
    data,
});
export const menuOpen = (data) => ({
    type: actionTypes.MENU_OPEN,
    data,
});
export const dialogOpen = (data) => ({
    type: actionTypes.DIALOG_OPEN,
    data,
});
export const verificationDialogOpen = (data) => ({
    type: actionTypes.VERIFICATION_DIALOG_OPEN,
    data,
});
export const stopLoader = () => ({
    type: actionTypes.STOP_LOADER,
});
export const startLoader = () => ({
    type: actionTypes.START_LOADER,
});
export const navBarActiveChange = (data) => ({
    type: actionTypes.NAVBAR_ACTIVE_CHANGE,
    data,
});
