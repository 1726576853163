import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import SectionHeader from '../Common/SectionHeader';

import paymentMethod from '../../../static/images/dashboard/payment-method-detail.png';
import buildtoPay from '../../../static/images/dashboard/build_to-pay.png';
import ImageModal from '../../UI/CustomElements/Popups/ImageModal/ImageModal';
import './faq.scss';

const NumberComponent = (props) => {
    const qesNumber = get(props, 'qesNumber', null);

    return (
        <Box
            sx={{
                backgroundColor: 'FaqSidebar.main',
            }}
            className="number-wrapper"
        >
            <Box
                sx={{
                    backgroundColor: 'FaqSidebar.main',
                    '&::after': {
                        content: '""',
                        width: '50px',
                        height: '5px',
                        backgroundColor: 'black.main',
                        '@media only screen and (max-width: 600px)': {
                            display: 'none',
                        },
                    },
                }}
                className="left-number-box"
            >
                <Box>
                    <Box
                        sx={{ backgroundColor: 'black.main' }}
                        className="number-container"
                    >
                        {qesNumber}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
const QuestionComponent = (props) => {
    const question = get(props, 'question', null);
    const quesNumber = get(props, 'quesNumber', null);

    return (
        <Box className="question-container">
            <Box>
                <Box
                    className="question-number-container"
                    sx={{ backgroundColor: 'black.main' }}
                >
                    {quesNumber}
                </Box>
            </Box>
            <Box>
                <h4 className="question-heading">{question}</h4>
            </Box>
        </Box>
    );
};
const FaqListComponent = (props) => {
    const listClasses = get(props, 'listClasses', null);
    const value = get(props, 'value', () => {});

    return (
        <Box className={listClasses}>
            {props?.children}
            <Typography
                component="p"
                sx={{ fontSize: '1em', color: 'white.main' }}
            >
                {value}
            </Typography>
        </Box>
    );
};

FaqListComponent.propTypes = {
    children: PropTypes.element,
};

const FAQ = () => {
    const [previewImageModalStatus, setPreviewImageModalStatus] =
        React.useState(false);
    const [previewImage, setPreviewImage] = React.useState('');
    const secondQesList = [
        ' Real-Time Payment',
        ' Virtual Card',
        ' Wire Transfer',
        ' Same-Day ACH',
        ' Next Day ACH',
        ' Standard ACH',
        ' Check',
    ];
    const thirdQuesList = [
        {
            qes: 'What is a Real-Time Payment or RTP?',
            list: [
                `RTP is a payment sent through the RTP Network that provides real-time funds availability to Payees.`,
                `Funds from an RTP payment are immediately received in your bank account within seconds of transfer. Funds are available 24 hours a day, including weekends and holidays.`,
            ],
        },
        {
            qes: `What is a Virtual Card/Single Use Account (SUA)?`,
            list: [
                `Virtual Card is another option for expedited instant payment.`,
                `Virtual Cards can be processed by anyone who accepts traditional credit card payments.`,
            ],
        },
        {
            qes: `What is a Wire Transfer?`,
            list: [
                ` A Wire Transfer is an electronic payment service used to move money between bank accounts.`,
                `Processing times range from same-day to 24-hour processing time from payment initiation.`,
            ],
        },
        {
            qes: `What is an ACH?`,
            list: [
                ` An ACH payment is a type of electronic bank-to-bank payment option.`,
                ` Payments made in the US via ACH are made through the ACH Network, rather than going
through the card networks such as Visa or Mastercard.`,
                `Same-Day ACH payments take less than 1 day to process, Next-Day ACH takes 1 day to process,
and Standard ACH payments take approximately 3 days to process to your bank account`,
            ],
        },
    ];
    return (
        <Box className="faq-container">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white.main',
                }}
            >
                <SectionHeader title="Frequently asked questions" />
            </Box>
            <Box className="faq-content-container">
                <Box className="single-question-container">
                    <NumberComponent qesNumber="1" />
                    <Box className="right-question-box">
                        <QuestionComponent
                            quesNumber="1"
                            question="What is Hyphen Wallet?"
                        />
                        <Typography
                            component="p"
                            sx={{ fontSize: '1em', color: 'white.main' }}
                        >
                            Hyphen Wallet enables your Payors to pay you, their
                            Payees, in an automated, secure and quicker process
                            via the electronic payment method of your choice.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%',
                                    backgroundColor: 'primary.main',
                                    color: 'pureWhite.main',
                                }}
                            >
                                <AttachMoneyRoundedIcon />
                            </Box>
                            <Typography
                                component="p"
                                sx={{ fontSize: '1em', color: 'white.main' }}
                            >
                                Ultimately, you the Payee will receive your
                                payment faster and more reliably using Hyphen
                                Wallet
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className="single-question-container">
                    <NumberComponent qesNumber="2" />
                    <Box className="right-question-box">
                        <QuestionComponent
                            quesNumber="2"
                            question="What payment methods can I choose from to be paid by my Payor?"
                        />
                        <Box
                            sx={{
                                width: '100%',

                                display: { sm: 'block', lg: 'flex' },
                                gap: '50px',
                                justifyContent: {
                                    sm: 'center',
                                    lg: 'flex-start',
                                },
                            }}
                        >
                            <Box className="payment-method-list-container">
                                {secondQesList.map((val) => (
                                    <FaqListComponent
                                        value={val}
                                        listClasses="icon-value-container"
                                    >
                                        <IconButton
                                            sx={{ color: 'primary.main' }}
                                        >
                                            <CheckRoundedIcon />
                                        </IconButton>
                                    </FaqListComponent>
                                ))}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    onClick={() => {
                                        setPreviewImage(paymentMethod);
                                        setPreviewImageModalStatus(true);
                                    }}
                                    sx={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <img
                                        title="Click to view image"
                                        className="Payment-image"
                                        width={400}
                                        src={paymentMethod}
                                        alt="test"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box className="single-question-container">
                    <NumberComponent qesNumber="3" />
                    <Box className="right-question-box">
                        <QuestionComponent
                            quesNumber="3"
                            question="What are various payment methods and how will
they affect my business?"
                        />
                        {thirdQuesList.map((val) => (
                            <Box>
                                <Typography
                                    component="p"
                                    sx={{
                                        fontSize: '1em',
                                        color: 'white.main',
                                    }}
                                >
                                    {val.qes}
                                </Typography>
                                {val.list.map((val2) => (
                                    <FaqListComponent
                                        value={val2}
                                        listClasses="icon-value-container"
                                    >
                                        <IconButton
                                            sx={{ color: 'primary.main' }}
                                            size="large"
                                        >
                                            <ArrowRightRoundedIcon />
                                        </IconButton>
                                    </FaqListComponent>
                                ))}
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box className="single-question-container">
                    <NumberComponent qesNumber="4" />
                    <Box className="right-question-box">
                        <QuestionComponent
                            quesNumber="4"
                            question="Will I get paid the same day that the Payor initiates payments?"
                        />
                        <Typography
                            component="p"
                            sx={{ fontSize: '1em', color: 'white.main' }}
                        >
                            Yes, enroll in Real-Time Payment or Virtual Card and
                            you will receive payment immediately. Enroll in
                            Same-Day ACH or Wire Transfer and you will receive
                            payment within 24 hours
                        </Typography>
                    </Box>
                </Box>
                <Box className="single-question-container">
                    <NumberComponent qesNumber="5" />
                    <Box className="right-question-box">
                        <Box>
                            <QuestionComponent
                                quesNumber="5"
                                question="Does my bank have to be RTP eligible for me to receive payments via RTP? "
                            />
                            <Typography
                                component="p"
                                sx={{ fontSize: '1em', color: 'white.main' }}
                            >
                                Yes. Over 70% of bank accounts are RTP eligible
                                and increasing all the time. To verify if your
                                bank is RTP eligible, visit:
                                <a
                                    href="https://www.theclearinghouse.org/payment-systems/rtp/rtp-participating-financial-institutions"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    &nbsp;
                                    https://www.theclearinghouse.org/payment-systems/rtp/rtp-participating-financial-institutions
                                </a>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Box
                                onClick={() => {
                                    setPreviewImage(buildtoPay);
                                    setPreviewImageModalStatus(true);
                                }}
                                sx={{
                                    cursor: 'pointer',
                                }}
                            >
                                <img
                                    title="Click to view image"
                                    className="build-to-pay-image"
                                    width={400}
                                    src={buildtoPay}
                                    alt="test"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="single-question-container">
                    <NumberComponent qesNumber="6" />
                    <Box
                        sx={{
                            color: 'white.main',
                        }}
                        className="right-question-box"
                    >
                        <QuestionComponent
                            quesNumber="6"
                            question="Is there a cost to use Hyphen Wallet? "
                        />
                        <Typography
                            component="p"
                            sx={{ fontSize: '1em', color: 'white.main' }}
                        >
                            Hyphen Wallet is free to sign-up for and is utilized
                            to view payment and remittance information.{' '}
                        </Typography>{' '}
                        <span className="font-italic">
                            *Payment method fees are subject to Payor discretion
                        </span>
                    </Box>
                </Box>
                <Box className="single-question-container">
                    <NumberComponent qesNumber="7" />
                    <Box className="right-question-box">
                        <QuestionComponent
                            quesNumber="7"
                            question="Am I required to sign a Hyphen Wallet Agreement? "
                        />
                        <Typography
                            component="p"
                            sx={{ fontSize: '1em', color: 'white.main' }}
                        >
                            Yes. To accept electronic payments, a signed Hyphen
                            Wallet agreement is required. This agreement is for
                            a 12-month term and auto-renews.
                        </Typography>
                    </Box>
                </Box>
                <Box className="single-question-container">
                    <NumberComponent qesNumber="8" />
                    <Box className="right-question-box">
                        <QuestionComponent
                            quesNumber="8"
                            question="Why do I need to sign the Hyphen Wallet agreement if I already have a SupplyPro account?"
                        />
                        <Typography
                            component="p"
                            sx={{ fontSize: '1em', color: 'white.main' }}
                        >
                            Hyphen Wallet is a free application, however, we
                            need to update your SupplyPro account to include
                            Hyphen Wallet.
                        </Typography>
                    </Box>
                </Box>
                <Box className="single-question-container">
                    <NumberComponent qesNumber="9" />
                    <Box className="right-question-box">
                        <QuestionComponent
                            quesNumber="9"
                            question="Why does Hyphen Wallet require the personal information of the company and owner?"
                        />
                        <Typography
                            component="p"
                            sx={{ fontSize: '1em', color: 'white.main' }}
                        >
                            Hyphen Wallet is a financial application, and we are
                            required to “Know Our Customer” to ensure that we
                            are exceeding all Federal Financial Compliance
                            regulations
                        </Typography>
                    </Box>
                </Box>
                <Box className="single-question-container">
                    <NumberComponent qesNumber="10" />
                    <Box className="right-question-box">
                        <QuestionComponent
                            quesNumber="10"
                            question="How do I access the Hyphen Wallet portal to view transactions, make changes to payment 
methods and update banking information?"
                        />
                        <Typography
                            component="p"
                            sx={{ fontSize: '1em', color: 'white.main' }}
                        >
                            Please visit{' '}
                            <a
                                href="https://wallet.hyphensolutions.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://wallet.hyphensolutions.com
                            </a>
                            &nbsp;to view transactions and make any payment
                            method or account changes.
                        </Typography>
                    </Box>
                </Box>
                <Box className="single-question-container">
                    <NumberComponent qesNumber="11" />
                    <Box className="right-question-box">
                        <QuestionComponent
                            quesNumber="11"
                            question="How long will it take for me to receive the first payment after signing up?"
                        />
                        <Typography
                            component="p"
                            sx={{ fontSize: '1em', color: 'white.main' }}
                        >
                            If a payment has been initiated by your
                            participating Payors, it will take 5-7 days from
                            signing up for Hyphen Wallet to receive your first
                            payment.
                        </Typography>
                    </Box>
                </Box>
                <Box className="single-question-container">
                    <NumberComponent qesNumber="12" />
                    <Box className="right-question-box">
                        <QuestionComponent
                            quesNumber="12"
                            question="Can I change my payment method at any time?"
                        />
                        <Typography
                            component="p"
                            sx={{ fontSize: '1em', color: 'white.main' }}
                        >
                            Yes, you can change your payment method at any time.
                            All changes can be made in the Hyphen Wallet portal:
                            <a
                                href="https://wallet.hyphensolutions.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                &nbsp;https://wallet.hyphensolutions.com
                            </a>
                        </Typography>
                    </Box>
                </Box>
                <Box className="single-question-container">
                    <NumberComponent qesNumber="13" />
                    <Box className="right-question-box">
                        <QuestionComponent
                            quesNumber="13"
                            question="What if I am already using Hyphen Wallet but need to receive payments from a new or different Payor?"
                        />
                        <Box
                            sx={{
                                color: 'white.main',
                            }}
                        >
                            <Typography
                                component="p"
                                sx={{ fontSize: '1em', color: 'white.main' }}
                            >
                                For Payees who are already using Hyphen Wallet
                                for another Payor, we ask that you refrain from
                                initiating a new registration. Instead,
                            </Typography>
                            <ol className="odered-list-spacing" type="a">
                                <li>
                                    Please log into your Wallet portal and make
                                    a request under the support tab to add a new
                                    Payor OR
                                </li>

                                <li>
                                    Reach out to Hyphen Wallet at{' '}
                                    <a href="mailto:support@hyphensolutions.com">
                                        support@hyphensolutions.com
                                    </a>{' '}
                                    to complete your request.
                                </li>
                            </ol>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <ImageModal
                open={previewImageModalStatus}
                closeHandler={() => setPreviewImageModalStatus(false)}
                image={previewImage}
            />
        </Box>
    );
};

export default FAQ;
