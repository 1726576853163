/* eslint-disable import/prefer-default-export */
import { routePath } from './RoutePath';
import hexagon1 from '../../static/images/homepageshapes/Polygon 1.png';
import hexagon2 from '../../static/images/homepageshapes/Polygon 2.png';
import hexagon3 from '../../static/images/homepageshapes/Polygon 3.png';
import hexagon4 from '../../static/images/homepageshapes/Polygon 4.png';

export const loc = {
    typeyourmessagehere: 'homepage-contactus-message',
    send: 'verify-submit',
    getcode: 'getcode',
    signup: 'homepage-signup',
    login: 'logIn',
    dark: 'dark',
    light: 'light',
    contactus: {
        title: 'homepage-contactus-title',
        des: 'homepage-contactus-description',
        getintouch: 'homepage-contactus-getInTouch',
        yourname: 'homepage-contactus-name',
        youremail: 'homepage-contactus-email',
    },
    watchvideo: {
        title: 'homepage-learn-title',
        des: 'homepage-learn-description',
        youtubeembededid: '1EM1srAx0wo',
    },
    infocard: {
        title: 'homepage-howitworks-title',
    },
    slider: {
        title: 'homepage-header-text',
        des: 'homepage-header-description',
        hexagon1,
        hexagon2,
        hexagon3,
        hexagon4,
    },
    footer: {
        copyrightStart: 'copyright-start',
        copyrightMiddle: 'copyright-middle',
        copyrightEnd: 'copyright-end',
        privacy: 'login-privacy-policy',
        terms: 'terms-conditions',
        privacyLink:
            'https://www.hyphensolutions.com/info/hyphenprivacystatement/',
        termsLink: 'termsandconditions.html',
        hyphenSolutionLink: 'https://www.hyphensolutions.com',
    },
    header: {
        navbar: [
            {
                label: 'homepage-navbar-home',
                route: '#home',
            },
            {
                label: 'homepage-navbar-howitworks',
                route: '#howitworks',
            },
            {
                label: 'homepage-navbar-contact',
                route: '#contactus',
            },
            // {
            //     label: 'home-page-about-us-btn',
            //     route: '/aboutus',
            // },
        ],
    },
    aboutUs: {
        videoIntro: {
            title: 'about-us',
            des: 'about-us describtion',
            youtubeembededid: 'c1HYuu7LCKo',
        },
    },
};
