import Cookie from 'js-cookie';

import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const initialState = {
    isAuthenticated: null,
    isLoading: false,
    data: null,
    error: null,
    imageUploadSuccessDialog: false,
};

// Auth
const authStart = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: true,
        data: null,
        error: null,
    });
const authSuccess = (state, action) =>
    updateObject(state, {
        isAuthenticated: true,
        isLoading: false,
        data: action.data,
        error: null,
    });
const authSigninChallenge = (state, action) => {
    // get cookie for sms challege only
    if (action.data) {
        Cookie.set('username', action.data.username);
        Cookie.set('token', action.data.Session);
    }

    return updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
        error: null,
    });
};
const authFail = (state, action) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
        error: action.error,
    });
const authKyc = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
    });

// Confirm forgot password
const confirmForgotPasswordStart = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: true,
        data: null,
        error: null,
    });
const confirmForgotPasswordSuccess = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
        error: null,
    });
const confirmForgotPasswordFail = (state, action) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
        error: action.error,
    });

// Delete avatar
const deleteAvatarStart = (state) =>
    updateObject(state, { isLoading: true, isComplete: false, error: null });

const deleteAvatarSuccess = (state) => {
    const { WalletCognitoUserSession, WalletUserSettings } = state.data;
    const walletCognitoUserSession = { ...WalletCognitoUserSession };
    const walletUserSettings = { ...WalletUserSettings };
    walletUserSettings.avatar = null;

    const newData = {
        WalletCognitoUserSession: walletCognitoUserSession,
        WalletUserSettings: walletUserSettings,
    };

    return updateObject(state, {
        isLoading: false,
        isComplete: true,
        error: null,
        data: newData,
    });
};

const deleteAvatarFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
        error: action.error,
    });

// PUT avatar
const putAvatarStart = (state) =>
    updateObject(state, { isLoading: true, isComplete: false, error: null });

const putAvatarSuccess = (state, action) => {
    const { WalletCognitoUserSession, WalletUserSettings } = state.data;
    const walletCognitoUserSession = { ...WalletCognitoUserSession };
    const walletUserSettings = { ...WalletUserSettings };

    walletUserSettings.avatar = {
        ...action.data,
    };

    const newData = {
        WalletCognitoUserSession: walletCognitoUserSession,
        WalletUserSettings: walletUserSettings,
    };

    return updateObject(state, {
        isLoading: false,
        isComplete: true,
        error: null,
        data: newData,
        imageUploadSuccessDialog: true,
    });
};

const putAvatarFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
        error: action.error,
        data: null,
    });

// Forgot password
const forgotPasswordStart = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: true,
        data: null,
        error: null,
    });
const forgotPasswordSuccess = (state, action) => {
    if (action.data) {
        Cookie.set('username', action.data.username);
    }
    return updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: action.data,
        error: null,
    });
};
const forgotPasswordFail = (state, action) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
        error: action.error,
    });

// Resend code
const resendCodeStart = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: true,
        data: null,
        error: null,
    });
const resendCodeSuccess = (state, action) => {
    if (action.data) {
        Cookie.set('username', action.data.username);
    }
    return updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: action.data,
        error: null,
    });
};
const resendCodeFail = (state, action) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
        error: action.error,
    });

// Signin challenge
const signinChallengeStart = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: true,
        data: null,
        error: null,
    });
const signinChallengeSuccess = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
        error: null,
    });
const signinChallengeFail = (state, action) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
        error: action.error,
    });

const imageSuccessPopupClose = (state) =>
    updateObject(state, {
        ...state,
        imageUploadSuccessDialog: false,
    });
// Signout
const signoutStart = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: true,
        data: null,
        error: null,
    });
const signoutSuccess = (state) => {
    // might need history for redirect?
    // cleanup state & local storage
    localStorage.removeItem('WalletCognitoUserSession');
    localStorage.removeItem('WalletUserSettings');
    return updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
        error: null,
    });
};
const signoutFail = (state, action) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
        error: action.error,
    });

// Update password
const updatePasswordStart = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: true,
        data: null,
        error: null,
    });
const updatePasswordSuccess = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
        error: null,
    });
const updatePasswordFail = (state, action) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
        error: action.error,
    });
// Kyc submission
const kycStart = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: true,
        data: null,
        error: null,
    });
const kycSubmission = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: true,
        data: null,
        error: null,
    });
const kycSuccess = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
        error: null,
    });
const kycFail = (state) =>
    updateObject(state, {
        isAuthenticated: null,
        isLoading: false,
        data: null,
    });

const reducer = (state = initialState, action) => {
    if (state === action) {
        return state;
    }
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_SIGNIN_CHALLENGE:
            return authSigninChallenge(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_KYC:
            return authKyc(state);
        case actionTypes.CONFIRM_FORGOT_PASSWORD_START:
            return confirmForgotPasswordStart(state);
        case actionTypes.CONFIRM_FORGOT_PASSWORD_SUCCESS:
            return confirmForgotPasswordSuccess(state);
        case actionTypes.CONFIRM_FORGOT_PASSWORD_FAIL:
            return confirmForgotPasswordFail(state, action);
        case actionTypes.DELETE_AVATAR_START:
            return deleteAvatarStart(state);
        case actionTypes.DELETE_AVATAR_SUCCESS:
            return deleteAvatarSuccess(state);
        case actionTypes.DELETE_AVATAR_FAIL:
            return deleteAvatarFail(state, action);
        case actionTypes.FORGOT_PASSWORD_START:
            return forgotPasswordStart(state);
        case actionTypes.FORGOT_PASSWORD_SUCCESS:
            return forgotPasswordSuccess(state, action);
        case actionTypes.FORGOT_PASSWORD_FAIL:
            return forgotPasswordFail(state, action);
        case actionTypes.PUT_AVATAR_START:
            return putAvatarStart(state);
        case actionTypes.PUT_AVATAR_SUCCESS:
            return putAvatarSuccess(state, action);
        case actionTypes.PUT_AVATAR_FAIL:
            return putAvatarFail(state, action);
        case actionTypes.RESEND_CODE_START:
            return resendCodeStart(state);
        case actionTypes.RESEND_CODE_SUCCESS:
            return resendCodeSuccess(state, action);
        case actionTypes.RESEND_CODE_FAIL:
            return resendCodeFail(state, action);
        case actionTypes.SIGNIN_CHALLENGE_START:
            return signinChallengeStart(state);
        case actionTypes.SIGNIN_CHALLENGE_SUCCESS:
            return signinChallengeSuccess(state, action);
        case actionTypes.SIGNIN_CHALLENGE_FAIL:
            return signinChallengeFail(state, action);
        case actionTypes.SIGNOUT_START:
            return signoutStart(state);
        case actionTypes.SIGNOUT_SUCCESS:
            return signoutSuccess(state);
        case actionTypes.SIGNOUT_FAIL:
            return signoutFail(state, action);
        case actionTypes.UPDATE_PASSWORD_START:
            return updatePasswordStart(state);
        case actionTypes.UPDATE_PASSWORD_SUCCESS:
            return updatePasswordSuccess(state);
        case actionTypes.UPDATE_PASSWORD_FAIL:
            return updatePasswordFail(state, action);
        case actionTypes.KYC_START:
            return kycStart(state);
        case actionTypes.KYC_SUCCESS:
            return kycSuccess(state);
        case actionTypes.KYC_SUBMISSION:
            return kycSubmission(state, action);
        case actionTypes.KYC_FAIL:
            return kycFail(state);
        case actionTypes.IMAGE_SUCCESS_DIALOG_CLOSE:
            return imageSuccessPopupClose(state);
        default:
            return state;
    }
};

export default reducer;
