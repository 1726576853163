import { Grid, Stack, Box } from '@mui/material';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardNavbar from './Common/DashboardNavbar/DashboardNavbar';
import Footer from '../../HomePage/Components/Footer/Footer';
import DashboardHeader from './Common/DashboardHeader';
import './dashboard-layout.scss';
import {
    dashLayoutAuthorized,
    routePath,
} from '../../../constants/strings/RoutePath';

const DashboardLayout = (props) => {
    const children = get(props, 'children', <></>);
    const location = useLocation();
    const { i18n } = useTranslation();

    const mode = useSelector((state) => state.homepage.mode);

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const navigate = useNavigate();
    useEffect(() => {
        const allowRerouting =
            location.pathname === routePath.login ||
            location.pathname === routePath.register ||
            location.pathname === routePath.homepage ||
            location.pathname === routePath.aboutus;
        if (isAuthenticated && allowRerouting) {
            localStorage.setItem('preferedLanguage', JSON.stringify('en'));
            i18n.changeLanguage('en');
            navigate(routePath.dashboard);
        }
    }, [isAuthenticated]);

    return (
        <>
            {dashLayoutAuthorized(location.pathname) ? (
                <Stack
                    className="wrapper_container"
                    sx={{ backgroundColor: 'dashboardMainBackgroud.main' }}
                >
                    <div className="main_header">
                        <DashboardHeader />

                        <div className="dashboard-navbar-container">
                            <DashboardNavbar />
                        </div>
                    </div>
                    <Box
                        className={`main-container ${
                            mode === 'light'
                                ? 'main-container-light'
                                : 'main-container-dark'
                        } `}
                        sx={{
                            backgroundColor: 'dashboardMainBackgroud.main',
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: 'dashboardMainBackgroud.main',
                            }}
                            className="content-container"
                        >
                            <Grid
                                container
                                className={`content-holder ${
                                    mode === 'light'
                                        ? 'content-holder-light'
                                        : 'content-holder-dark'
                                } `}
                                sx={{
                                    backgroundColor:
                                        'dashboardMainBackgroud.main',
                                }}
                            >
                                {location.pathname !== routePath.dashboard ? (
                                    <Grid
                                        container
                                        className="content-wrapper border-radius-dash"
                                        sx={{
                                            backgroundColor:
                                                'dashboardContainerBackgroud.main',
                                        }}
                                    >
                                        <Grid item xs={12} className="">
                                            {children}
                                        </Grid>
                                    </Grid>
                                ) : (
                                    children
                                )}
                            </Grid>
                        </Box>
                    </Box>
                    <Box
                        className={`footer-container ${
                            mode === 'light'
                                ? 'main-container-light'
                                : 'main-container-dark'
                        } `}
                    >
                        <Footer />
                    </Box>
                </Stack>
            ) : (
                // Login, Register pages
                <main>{children}</main>
            )}
        </>
    );
};

export default DashboardLayout;
