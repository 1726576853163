import { put } from 'redux-saga/effects';
import axios from 'axios';

import { getSessionToken } from '../../constants/session';

import * as actions from '../actions/index';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const putUserSupportResource = process.env.REACT_APP_PUT_USER_SUPPORT;

export function* putUserSupportSaga(action) {
    const url = baseUrl + putUserSupportResource;

    try {
        yield put(actions.putUserSupportStart());

        const body = action.data;

        const sessionToken = getSessionToken();

        yield axios.post(
            url,
            JSON.stringify({
                ...body,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionToken,
                },
            },
        );
        yield put(actions.putUserSupportSuccess());
    } catch (error) {
        yield put(actions.putUserSupportFail(error.message));
    }
}

export function* closeUserSupportDialogSaga() {
    yield put(actions.closeUserSupportDialogSuccess());
}
