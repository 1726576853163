import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import profilePicSrc from '../../../../../static/images/dashboard/profile-pic.svg';
import * as actions from '../../../../../store/actions';
import { routePath } from '../../../../../constants/strings/RoutePath';

import './profile-dropdown.scss';

function nameShortener(name) {
    if (!name) return;
    const splitName = name?.toUpperCase().split(' ');
    const firstNameInitial = splitName[0][0];
    const lastNameInitial =
        splitName.length >= 2 ? splitName[splitName.length - 1][0] : '';
    return `${firstNameInitial} ${lastNameInitial}`;
}

const DropdownMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const walletUser = useSelector(
        (state) => state?.auth?.data?.WalletUserSettings ?? {},
    );

    const [anchorElement, setAnchorElement] = useState();

    const open = !isUndefined(anchorElement);

    const handleClick = (event) => setAnchorElement(event.currentTarget);
    const handleClose = (action) => {
        setAnchorElement();
        if (action === 'signout') dispatch(actions.signout());
        if (action === 'accountSettings') navigate(routePath.accountSettings);
    };

    return (
        <>
            <Button
                className="profile-dropdown-btn"
                aria-controls={open ? 'customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                disableElevation
                onClick={handleClick}
                startIcon={
                    <img
                        width={28}
                        height={28}
                        style={{ borderRadius: '50%' }}
                        src={walletUser?.avatar?.signedPath ?? profilePicSrc}
                        alt="Avatar"
                    />
                }
                endIcon={<ArrowDropDownIcon />}
            >
                {nameShortener(walletUser?.contactName)}
            </Button>
            <Menu
                MenuListProps={{
                    className: 'profile-menu-container',
                    'aria-labelledby': 'customized-button',
                }}
                PopoverClasses={anchorElement}
                anchorEl={anchorElement}
                open={open}
                onClose={() => handleClose()}
            >
                <MenuItem
                    className="profile-menuitem border-bottom"
                    onClick={() => handleClose('accountSettings')}
                    disableRipple
                >
                    Account settings
                </MenuItem>

                <MenuItem
                    className="profile-menuitem"
                    onClick={() => handleClose('signout')}
                    disableRipple
                >
                    Sign out
                </MenuItem>
            </Menu>
        </>
    );
};

export default DropdownMenu;
