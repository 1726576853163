import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const initialState = {
    show: false,
};

const setIsVisible = (state) =>
    updateObject(state, {
        show: true,
    });

const setIsNotVisible = (state) =>
    updateObject(state, {
        show: false,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW:
            return setIsVisible(state);
        case actionTypes.HIDE:
            return setIsNotVisible(state);
        default:
            return state;
    }
};

export default reducer;
