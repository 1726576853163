import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const initialState = {
    isLoading: false,
    isComplete: null,
    error: null,
    data: null,
};

const activityStart = (state) =>
    updateObject(state, { isLoading: true, isComplete: false, error: null });

const activitySuccess = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: true,
        error: null,
        data: action.data,
    });

const activityFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
        error: action.error,
        data: null,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ACTIVITY_START:
            return activityStart(state);
        case actionTypes.ACTIVITY_SUCCESS:
            return activitySuccess(state, action);
        case actionTypes.ACTIVITY_FAIL:
            return activityFail(state, action);
        default:
            return state;
    }
};

export default reducer;
