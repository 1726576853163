import React from 'react';

import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#3A4043',
        background: 'rgba(58,64,67, 0.6)',
    },
    root: {
        position: 'relative',
    },
    top: {
        color: '#F47521',
        animationDuration: '550ms',
    },
}));

function ProgressSpinner() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Backdrop className={classes.backdrop} open>
                <CircularProgress
                    // color="#F47521"
                    color="inherit"
                    variant="indeterminate"
                    disableShrink
                    className={classes.top}
                    size={64}
                    thickness={4}
                />
            </Backdrop>
        </div>
    );
}

export default ProgressSpinner;
