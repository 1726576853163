import WalletIcon from '@mui/icons-material/Wallet';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';
import { routePath } from '../RoutePath';

const navBtnList = [
    {
        label: 'Payor',
        icon: <RecentActorsIcon />,
        route: routePath.relationships,
        className: '',
        userGroup: 'both',
    },
    {
        label: 'Dashboard',
        icon: <DashboardIcon />,
        route: routePath.dashboard,
        className: '',
        userGroup: 'both',
    },
    {
        label: 'Batch Creation',
        key: 'batch_creation',
        icon: <WalletIcon />,
        route: routePath.walletPay,
        className: '',
        userGroup: 'Builder',
    },
    {
        label: 'Payment Method',
        icon: <WalletIcon />,
        route: routePath.paymentMethods,
        className: '',
        userGroup: 'Supplier',
    },
    {
        label: 'Pending Payments',
        key: 'pending_payment',
        icon: <MonetizationOnIcon />,
        route: routePath.pendingPayments,
        className: '',
        userGroup: 'Builder',
    },
    {
        label: 'Payments',
        icon: <AccountBalanceWalletIcon />,
        route: routePath.payments,
        className: '',
        userGroup: 'both',
    },

    {
        label: 'Support',
        icon: <HeadsetMicIcon />,
        route: routePath.support,
        className: '',
        userGroup: 'both',
    },
    {
        label: 'FAQ',
        icon: <PhonelinkSetupIcon />,
        route: routePath.faq,
        className: '',
        userGroup: 'Builder',
    },
    {
        label: 'FAQ',
        icon: <LiveHelpRoundedIcon />,
        route: routePath.faq,
        className: '',
        userGroup: 'Supplier',
    },
];

export default navBtnList;
