import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const initialState = { open: false, severityLevel: 'info', message: '' };
const alertDialogHideStart = (state) =>
    updateObject(state, { open: false, severityLevel: 'info', message: '' });

const alertDialogHideSuccess = (state, action) =>
    updateObject(state, {
        open: false,
        severityLevel: action.data.severityLevel,
        message: '',
    });

const alertDialogHideFail = (state, action) =>
    updateObject(state, {
        open: false,
        severityLevel: 'error',
        message: action.error,
    });

const alertDialogSetStart = (state) =>
    updateObject(state, { open: false, severityLevel: 'info', message: '' });

const alertDialogSetSuccess = (state, action) =>
    updateObject(state, {
        open: action.data.open,
        severityLevel: action.data.severityLevel,
        message: action.data.message,
    });

const alertDialogSetFail = (state, action) =>
    updateObject(state, {
        open: false,
        severityLevel: 'error',
        message: action.error,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ALERT_DIALOG_HIDE_START:
            return alertDialogHideStart(state);
        case actionTypes.ALERT_DIALOG_HIDE_SUCCESS:
            return alertDialogHideSuccess(state, action);
        case actionTypes.ALERT_DIALOG_HIDE_FAIL:
            return alertDialogHideFail(state, action);
        case actionTypes.ALERT_DIALOG_SET_START:
            return alertDialogSetStart(state);
        case actionTypes.ALERT_DIALOG_SET_SUCCESS:
            return alertDialogSetSuccess(state, action);
        case actionTypes.ALERT_DIALOG_SET_FAIL:
            return alertDialogSetFail(state, action);
        default:
            return state;
    }
};

export default reducer;
