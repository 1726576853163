import * as actionTypes from '../../actions/types';
import { updateObject } from '../../../constants/utility';

const initialState = {
    isLoading: false,
    isComplete: null,
    error: null,
    data: null,
};

const relationshipsStart = (state) =>
    updateObject(state, { isLoading: true, isComplete: false, error: null });

const relationshipsSuccess = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: true,
        error: null,
        data: action.data.relationships,
    });

const relationshipsFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
        error: action.error,
        data: null,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RELATIONSHIPS_START:
            return relationshipsStart(state);
        case actionTypes.RELATIONSHIPS_SUCCESS:
            return relationshipsSuccess(state, action);
        case actionTypes.RELATIONSHIPS_FAIL:
            return relationshipsFail(state, action);
        default:
            return state;
    }
};

export default reducer;
