/* eslint-disable react/jsx-props-no-spreading */
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { loc } from '../../../../constants/strings/local';
import './footer.scss';
import Faq from '../../../../static/resources/wallet-faq.pdf';
import builderFaq from '../../../../static/resources/wallet-builder-faq.pdf';

const Footer = (props) => {
    const footerClassName = get(props, 'footerClassName', 'privacy-footer');
    const { t: trans } = useTranslation();
    const commonAttributesFunction = (otherClass) => ({
        underline: 'none',
        rel: 'noopener noreferrer',
        target: '_blank',
        color: '#939191',
        sx: { ':hover': { color: 'primary.main' } },
        className: `body2 ${otherClass}`,
    });
    const commonAttributes = commonAttributesFunction('');
    const walletUser = useSelector(
        (state) => state?.auth?.data?.WalletUserSettings,
    );
    return (
        <Stack
            p="10px"
            direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
            justifyContent="center"
            className={footerClassName}
        >
            <Box className="footer-text-align">
                <Box>
                    <span className="footer-text m-0">
                        {trans(loc.footer.copyrightStart)}&nbsp;
                    </span>
                </Box>
                <Box>
                    <Link
                        href={loc.footer.hyphenSolutionLink}
                        {...commonAttributesFunction('footer-company-name')}
                    >
                        {trans(loc.footer.copyrightMiddle)}&nbsp;
                    </Link>
                </Box>

                <span className="footer-text m-0">
                    {trans(loc.footer.copyrightEnd)}&nbsp;
                </span>
            </Box>
            <Stack className="justify-center footer-se" direction="row">
                <Link href={loc.footer.privacyLink} {...commonAttributes}>
                    {trans(loc.footer.privacy)}
                </Link>
                <span className="body2 footer-company-name font-change-footer">
                    {' '}
                    &nbsp;|&nbsp;
                </span>
                <Link href={loc.footer.termsLink} {...commonAttributes}>
                    {trans(loc.footer.terms)}
                </Link>
                <span className="body2 footer-company-name font-change-footer">
                    {' '}
                    &nbsp;|&nbsp;
                </span>
                <Link
                    href={
                        walletUser?.usergroupType === 'Builder'
                            ? builderFaq
                            : Faq
                    }
                    {...commonAttributes}
                >
                    FAQ
                </Link>
            </Stack>
        </Stack>
    );
};

export default Footer;
