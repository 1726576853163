import * as actionTypes from '../../actions/types';
import { updateObject } from '../../../constants/utility';

const initialState = {
    isLoading: false,
    isComplete: null,
    error: null,
    data: null,
    popupData: null,
    detailDialog: false,
};

const walletPayStart = (state) =>
    updateObject(state, { isLoading: true, isComplete: false, error: null });

const walletPaySuccess = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: true,
        error: null,
        data: action.data.walletPay,
    });

const walletPayFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
        error: action.error,
        data: null,
    });
const payDetailsDialogStatus = (state, action) =>
    updateObject(state, {
        detailDialog: action.status,
    });
const realTimeBatchStart = (state) =>
    updateObject(state, { isLoading: true, isComplete: false, error: null });

const realTimeBatchSuccess = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: true,
        error: null,
        popupData: action.data,
    });

const realTimeBatchFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
        error: action?.error,
        data: null,
    });
const postBatchCsvStart = (state) =>
    updateObject(state, { isLoading: true, isComplete: false, error: null });

const postBatchCsvSuccess = (state) =>
    updateObject(state, {
        isLoading: false,
        isComplete: true,
        error: null,
    });

const postBatchCsvFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
        error: action?.error,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.WALLETPAY_START:
            return walletPayStart(state);
        case actionTypes.WALLETPAY_SUCCESS:
            return walletPaySuccess(state, action);
        case actionTypes.WALLETPAY_FAIL:
            return walletPayFail(state, action);
        case actionTypes.REAL_TIME_BATCH_START:
            return realTimeBatchStart(state);
        case actionTypes.REAL_TIME_BATCH_SUCCESS:
            return realTimeBatchSuccess(state, action);
        case actionTypes.REAL_TIME_BATCH_FAIL:
            return realTimeBatchFail(state, action);
        case actionTypes.POST_BATCH_CSV_START:
            return postBatchCsvStart(state);
        case actionTypes.POST_BATCH_CSV_SUCCESS:
            return postBatchCsvSuccess(state, action);
        case actionTypes.POST_BATCH_CSV_FAIL:
            return postBatchCsvFail(state, action);
        case actionTypes.PAY_DETAIL_DIALOG_STATUS:
            return payDetailsDialogStatus(state, action);
        default:
            return state;
    }
};

export default reducer;
