/* eslint-disable import/prefer-default-export */
import * as actionTypes from './types';

const featurePages = new Map([
    ['/login', 0],
    ['/register', 0],
]);

export const isVisibleFeature = (pathname) =>
    featurePages.has(pathname)
        ? { type: actionTypes.SHOW }
        : { type: actionTypes.HIDE };
