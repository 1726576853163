import { put } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions/index';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const resource = process.env.REACT_APP_STARTUP_API;

function* startupSaga() {
    const url = baseUrl + resource;

    yield put(actions.startupStart());

    try {
        const response = yield axios.get(url);

        yield put(actions.startupSuccess(response.data.result));
    } catch (error) {
        yield put(actions.startupFail(error.message));
    }
}
export default startupSaga;
