import React, { useEffect, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import {
    Route,
    Routes,
    Navigate,
    useNavigate,
    useLocation,
} from 'react-router-dom';
import ProgressSpinner from '../../components/UI/ProgressSpinner/ProgressSpinner';
import { getCognitoGroup } from '../../constants/session';
import {
    allowAutoRerouting,
    routePath,
} from '../../constants/strings/RoutePath';

// adding lazy will make the image load slow
import FAQ from '../../components/WalletDashBoard/FAQ/FAQ';
import BuilderFAQ from '../../components/WalletDashBoard/FAQ/BuilderFAQ';

const Login = lazy(() => import('../../components/Authentication/SignIn'));
const SignUp = lazy(() => import('../../components/Authentication/SignUp'));
const Dashboard = lazy(() => import('../../containers/Dashboard/Dashboard'));
const HowWalletWorks = lazy(() =>
    import('../../components/WalletDashBoard/HowWalletWorks/HowWalletWorks'),
);
const AccountSettings = lazy(() =>
    import('../../components/WalletDashBoard/AccountSettings/AccountSettings'),
);
const Batches = lazy(() => import('../../containers/Batches/Batches'));
const Activity = lazy(() => import('../../containers/Activity/Activity'));
const Register = lazy(() => import('../../containers/Register/Register'));
const Relationships = lazy(() =>
    import('../../containers/Admin/Relationships/Relationships'),
);
const Error = lazy(() => import('../../containers/Error/Error'));
const NotFound = lazy(() => import('../../components/NotFound/NotFound'));
const AuthLayout = lazy(() => import('./PrivateRoute/PrivateRoute'));
const HomePage = lazy(() => import('../../components/HomePage/HomePage'));
const MaintenancePage = lazy(() =>
    import('../../components/MaintenancePage/MaintenancePage'),
);
const KycSubmission = lazy(() =>
    import('../../components/Authentication/KycSubmission/KycSubmission'),
);
const DashboardLandingPage = lazy(() =>
    import(
        '../../components/WalletDashBoard/DashboardLandingPage/DashboardLandingPage'
    ),
);
const Support = lazy(() =>
    import('../../components/WalletDashBoard/Support/Support'),
);
const BuilderSupplierRelationship = lazy(() =>
    import(
        '../../components/WalletDashBoard/BuilderSupplierRelationship/BuilderSupplierRelationship'
    ),
);
const PaymentMethod = lazy(() =>
    import('../../components/WalletDashBoard/PaymentMethod/PaymentMethod'),
);
const WalletPay = lazy(() =>
    import('../../components/WalletDashBoard/WalletPay/WalletPay'),
);
const Payments = lazy(() =>
    import('../../components/WalletDashBoard/Payments/Payments'),
);
const PendingPayments = lazy(() =>
    import('../../components/WalletDashBoard/Payments/PendingPayments'),
);

const MainRoutes = () => {
    const cognitoGroup = getCognitoGroup();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const allowRerouting = allowAutoRerouting(location.pathname);
        if (isAuthenticated && allowRerouting) {
            navigate(routePath.dashboard);
        }
    }, [isAuthenticated, location.pathname, navigate]);

    const walletUser = useSelector(
        (state) => state?.auth?.data?.WalletUserSettings,
    );
    return (
        <Routes>
            <Route
                path={routePath.homepage}
                element={
                    <Suspense fallback={<ProgressSpinner />}>
                        <HomePage />
                    </Suspense>
                }
            />
            <Route
                path={routePath.aboutus}
                element={
                    <Suspense fallback={<ProgressSpinner />}>
                        <HomePage />
                    </Suspense>
                }
            />
            <Route
                path={routePath.login}
                element={
                    <Suspense fallback={<ProgressSpinner />}>
                        <Login />
                    </Suspense>
                }
            />
            <Route
                path={routePath.forgotpassword}
                element={
                    <Suspense fallback={<ProgressSpinner />}>
                        <Login />
                    </Suspense>
                }
            />
            <Route
                path={routePath.confirmforgotpassword}
                element={
                    <Suspense fallback={<ProgressSpinner />}>
                        <Login />
                    </Suspense>
                }
            />
            <Route
                path={routePath.updatepassword}
                element={
                    <Suspense fallback={<ProgressSpinner />}>
                        <Login />
                    </Suspense>
                }
            />
            <Route
                path="/kycsubmission"
                element={
                    <Suspense fallback={<ProgressSpinner />}>
                        <KycSubmission />
                    </Suspense>
                }
            />
            <Route
                path={routePath.register}
                element={
                    <Suspense fallback={<ProgressSpinner />}>
                        <SignUp />
                    </Suspense>
                }
            />
            <Route
                path="/signup"
                element={
                    <Suspense fallback={<ProgressSpinner />}>
                        <Register />
                    </Suspense>
                }
            />

            <Route
                element={
                    <Suspense fallback={<ProgressSpinner />}>
                        <AuthLayout />
                    </Suspense>
                }
            >
                <Route
                    path={routePath.accountSettings}
                    element={
                        <Suspense fallback={<ProgressSpinner />}>
                            <AccountSettings />
                        </Suspense>
                    }
                />
                <Route
                    path={routePath.dashboard}
                    element={
                        <Suspense fallback={<ProgressSpinner />}>
                            <DashboardLandingPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/activity"
                    element={
                        <Suspense fallback={<ProgressSpinner />}>
                            <Activity />
                        </Suspense>
                    }
                />
                <Route
                    path="/batches"
                    element={
                        <Suspense fallback={<ProgressSpinner />}>
                            <Batches />
                        </Suspense>
                    }
                />
                <Route
                    path="/dashboard"
                    element={
                        <Suspense fallback={<ProgressSpinner />}>
                            <Dashboard />
                        </Suspense>
                    }
                />
                <Route
                    path={routePath.howWalletWorks}
                    element={
                        <Suspense fallback={<ProgressSpinner />}>
                            <HowWalletWorks />
                        </Suspense>
                    }
                />
                <Route
                    path={routePath.faq}
                    element={
                        <Suspense fallback={<ProgressSpinner />}>
                            {walletUser?.usergroupType === 'Builder' ? (
                                <BuilderFAQ />
                            ) : (
                                <FAQ />
                            )}
                        </Suspense>
                    }
                />
                <Route
                    path={routePath.support}
                    element={
                        <Suspense fallback={<ProgressSpinner />}>
                            <Support />
                        </Suspense>
                    }
                />
                <Route
                    path={routePath.relationships}
                    element={
                        <Suspense fallback={<ProgressSpinner />}>
                            <BuilderSupplierRelationship />
                        </Suspense>
                    }
                />
                <Route
                    path={routePath.walletPay}
                    element={
                        <Suspense fallback={<ProgressSpinner />}>
                            <WalletPay />
                        </Suspense>
                    }
                />
                <Route
                    path={routePath.payments}
                    element={
                        <Suspense fallback={<ProgressSpinner />}>
                            <Payments />
                        </Suspense>
                    }
                />
                <Route
                    path={routePath.pendingPayments}
                    element={
                        <Suspense fallback={<ProgressSpinner />}>
                            <PendingPayments />
                        </Suspense>
                    }
                />
                <Route
                    path={routePath.paymentMethods}
                    element={<PaymentMethod />}
                />
                <Route
                    path="/admin/relationships"
                    element={
                        ['usergroup_admin'].indexOf(cognitoGroup) === -1 ? (
                            <Navigate replace to="/" />
                        ) : (
                            <Relationships />
                        )
                    }
                />

                {/* Error routing should always be private */}
                <Route
                    path="/error"
                    element={
                        <Suspense fallback={<ProgressSpinner />}>
                            <Error />
                        </Suspense>
                    }
                />
            </Route>
            <Route
                path={routePath.maintenance}
                element={
                    <Suspense fallback={<ProgressSpinner />}>
                        <MaintenancePage />
                    </Suspense>
                }
            />
            <Route
                path="*"
                element={
                    <Suspense fallback={<ProgressSpinner />}>
                        <NotFound />
                    </Suspense>
                }
            />

            {/* <Route path="/aboutUs" element={<AboutUsPage />} /> */}
        </Routes>
    );
};

export default MainRoutes;

// {
//     /* <Route

//                 path='/summary-report/:transactionId'
//                 element={<Suspense fallback={<ProgressSpinner/>}><SummaryReport/></Suspense>}
//             /> */
// }
// {
//     /* <Route

//                 path='/code-challenge'
//                 element={<Suspense fallback={<ProgressSpinner/>}><CodeChallenge/></Suspense>}
//             /> */
// }
