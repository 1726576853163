/* eslint-disable camelcase */
import { put } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions/index';

import { getSessionToken, getUserEmail } from '../../constants/session';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const resource = process.env.REACT_APP_PENDING_PAYMENTS;

export default function* getPendingPaymentsSaga(action) {
    try {
        yield put(actions.pendingPaymentsStart());

        const { lien, order_by, sort_order } = action.data;
        yield put(actions.pendingTableFilter(action.data));
        yield put(
            actions.pendingTableSortValues({
                columnName: order_by ?? '',
                columnOrder: sort_order ?? 'asc',
            }),
        );
        const email = getUserEmail();
        const url = baseUrl + resource;
        let lienValue;
        const sessionToken = getSessionToken();
        if (lien === 0) {
            lienValue = false;
        } else if (lien === 1) {
            lienValue = true;
        } else {
            lienValue = null;
        }
        const response = yield axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionToken,
            },
            params: {
                ...action.data,
                username: email,

                lien: lienValue,
            },
        });
        const data = {
            pendingPayments: response.data.result ?? null,
        };

        yield put(actions.pendingPaymentsSuccess(data));
    } catch (error) {
        yield put(actions.pendingPaymentsFail(error));
    }
}
