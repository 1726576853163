import * as actionTypes from './types';

// Auth/Signin: Start, Success, Fail
export const authStart = () => ({
    type: actionTypes.AUTH_START,
});
export const signin = (data) => ({
    type: actionTypes.AUTH,
    data,
});
export const authSuccess = (data) => ({
    type: actionTypes.AUTH_SUCCESS,
    data,
});
export const authSigninChallenge = (data) => ({
    type: actionTypes.AUTH_SIGNIN_CHALLENGE,
    data,
});
export const authFail = (error) => ({
    type: actionTypes.AUTH_FAIL,
    error,
});
export const authKyc = () => ({
    type: actionTypes.AUTH_KYC,
});
// Auth/kyc: Start, Success, Fail
export const kycStart = () => ({
    type: actionTypes.KYC_START,
});
export const kycSubmission = (data) => ({
    type: actionTypes.KYC_SUBMISSION,
    data,
});
export const kycSuccess = (data) => ({
    type: actionTypes.KYC_SUCCESS,
    data,
});

export const kycFail = (error) => ({
    type: actionTypes.KYC_FAIL,
    error,
});

// Sms challenge
export const confirmSigninStart = () => ({
    type: actionTypes.CONFIRM_SIGNIN_START,
});
export const confirmSignin = (data) => ({
    type: actionTypes.CONFIRM_SIGNIN,
    data,
});
export const confirmSigninSuccess = (data) => ({
    type: actionTypes.CONFIRM_SIGNIN_SUCCESS,
    data,
});
export const confirmSigninFail = (error) => ({
    type: actionTypes.CONFIRM_SIGNIN_FAIL,
    error,
});

// Signin challenge
export const signinChallengeStart = () => ({
    type: actionTypes.SIGNIN_CHALLENGE_START,
});
export const signinChallenge = () => ({
    type: actionTypes.SIGNIN_CHALLENGE,
});
export const signinChallengeSuccess = () => ({
    type: actionTypes.SIGNIN_CHALLENGE_SUCCESS,
});
export const signinChallengeFail = (error) => ({
    type: actionTypes.SIGNIN_CHALLENGE_FAIL,
    error,
});

// Forgot password
export const forgotPasswordStart = () => ({
    type: actionTypes.FORGOT_PASSWORD_START,
});
export const forgotPassword = (data) => ({
    type: actionTypes.FORGOT_PASSWORD,
    data,
});
export const forgotPasswordSuccess = (data) => ({
    type: actionTypes.FORGOT_PASSWORD_SUCCESS,
    data,
});
export const forgotPasswordFail = (error) => ({
    type: actionTypes.FORGOT_PASSWORD_FAIL,
    error,
});

// Reset password
export const confirmForgotPasswordStart = () => ({
    type: actionTypes.CONFIRM_FORGOT_PASSWORD_START,
});
export const confirmForgotPassword = (data) => ({
    type: actionTypes.CONFIRM_FORGOT_PASSWORD,
    data,
});
export const confirmForgotPasswordSuccess = (data) => ({
    type: actionTypes.CONFIRM_FORGOT_PASSWORD_SUCCESS,
    data,
});
export const confirmForgotPasswordFail = (error) => ({
    type: actionTypes.CONFIRM_FORGOT_PASSWORD_FAIL,
    error,
});

// Update password
export const updatePasswordStart = () => ({
    type: actionTypes.UPDATE_PASSWORD_START,
});
export const updatePassword = (data) => ({
    type: actionTypes.UPDATE_PASSWORD,
    data,
});
export const updatePasswordSuccess = () => ({
    type: actionTypes.UPDATE_PASSWORD_SUCCESS,
});
export const updatePasswordFail = (error) => ({
    type: actionTypes.UPDATE_PASSWORD_FAIL,
    error,
});
export const imageSuccessPopupClose = () => ({
    type: actionTypes.IMAGE_SUCCESS_DIALOG_CLOSE,
});

// resend code
export const resendCodeStart = () => ({
    type: actionTypes.RESEND_CODE_START,
});
export const resendCode = (data) => ({
    type: actionTypes.RESEND_CODE,
    data,
});
export const resendCodeSuccess = (data) => ({
    type: actionTypes.RESEND_CODE_SUCCESS,
    data,
});
export const resendCodeFail = (error) => ({
    type: actionTypes.RESEND_CODE_FAIL,
    error,
});

// Signout
export const signoutStart = () => ({
    type: actionTypes.SIGNOUT_START,
});
export const signout = () => ({
    type: actionTypes.SIGNOUT,
});
export const signoutSuccess = () => ({
    type: actionTypes.SIGNOUT_SUCCESS,
});
export const signoutFail = (error) => ({
    type: actionTypes.SIGNOUT_FAIL,
    error,
});

// avatar PUT
export const putAvatarStart = () => ({
    type: actionTypes.PUT_AVATAR_START,
});
export const putAvatar = (file, filename, filetype) => ({
    type: actionTypes.PUT_AVATAR,
    file,
    filename,
    filetype,
});
export const putAvatarSuccess = (data) => ({
    type: actionTypes.PUT_AVATAR_SUCCESS,
    data,
});
export const putAvatarFail = (error) => ({
    type: actionTypes.PUT_AVATAR_FAIL,
    error,
});

// Delete avatar
export const deleteAvatarStart = () => ({
    type: actionTypes.DELETE_AVATAR_START,
});
export const deleteAvatar = (filename) => ({
    type: actionTypes.DELETE_AVATAR,
    filename,
});
export const deleteAvatarSuccess = () => ({
    type: actionTypes.DELETE_AVATAR_SUCCESS,
});
export const deleteAvatarFail = (error) => ({
    type: actionTypes.DELETE_AVATAR_FAIL,
    error,
});
