import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const initialState = {
    isLoading: false,
    error: null,
    data: null,
};

const startupStart = (state) =>
    updateObject(state, { isLoading: true, error: null });

const startupSuccess = (state, action) =>
    updateObject(state, {
        isLoading: false,
        error: null,
        data: action.data,
    });

const startupFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        error: action.error,
        data: null,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STARTUP_START:
            return startupStart(state);
        case actionTypes.STARTUP_SUCCESS:
            return startupSuccess(state, action);
        case actionTypes.STARTUP_FAIL:
            return startupFail(state, action);
        default:
            return state;
    }
};

export default reducer;
