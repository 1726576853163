import { Box } from '@mui/material';
import { get } from 'lodash';
import React from 'react';

const SectionHeader = (props) => {
    const title = get(props, 'title', '');
    return (
        <Box className="title-wrapper">
            <h3>{title}</h3>
        </Box>
    );
};

export default SectionHeader;
