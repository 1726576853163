import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { get } from 'lodash';
import { Box } from '@mui/material';
import './menu-drop-down.scss';

const MenuDropDown = (props) => {
    const options = get(props, 'options', []);
    const active = get(props, 'active', { index: '', value: '' });
    const userGroupType = get(props, 'userGroupType', '');
    const menuClickHandler = get(props, 'menuClickHandler', () => {});

    const handleChange = (event, e) => menuClickHandler(event, e.props.name);
    return (
        <Box className="select-dropdown-container">
            <Select
                value={active.value}
                onChange={handleChange}
                displayEmpty
                autoWidth
            >
                {options?.map((item, index) => (
                    <MenuItem
                        // The component doesnt have a classname attribute so adding a inline style to increase the popup width with the parent component change the with here when changing it in scss
                        sx={{ width: 'calc( 100vw - 42px)' }}
                        key={`${index}${item}`}
                        value={item}
                        name={index}
                    >
                        {userGroupType === 'Builder' && item === 'Builder'
                            ? 'Payees'
                            : item}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};

export default MenuDropDown;
