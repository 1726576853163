import * as actionTypes from './types';

export const putUserSupportStart = () => ({
    type: actionTypes.PUT_USER_SUPPORT_START,
});

export const putUserSupportSuccess = (data) => ({
    type: actionTypes.PUT_USER_SUPPORT_SUCCESS,
    data,
});

export const putUserSupportFail = (error) => ({
    type: actionTypes.PUT_USER_SUPPORT_FAIL,
    error,
});

export const putUserSupport = (data) => ({
    type: actionTypes.PUT_USER_SUPPORT,
    data,
});

export const closeUserSupportDialog = () => ({
    type: actionTypes.CLOSE_USER_SUPPORT_DIALOG,
});

export const closeUserSupportDialogSuccess = () => ({
    type: actionTypes.CLOSE_USER_SUPPORT_DIALOG_SUCCESS,
});
