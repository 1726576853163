import Cookie from 'js-cookie';
import { getState } from './localStorage-helper';

export const getCognitoGroup = () => {
    const accountSettings = getState('WalletUserSettings');
    return accountSettings ? JSON.parse(accountSettings).cognitoGroup : null;
};

export const getContactName = () => {
    const accountSettings = getState('WalletUserSettings');
    return accountSettings ? JSON.parse(accountSettings).contactName : null;
};

export const usergroupAdminOnly = () => {
    const requiredRole = 'usergroup_admin';
    const accountSettings = getState('WalletUserSettings');
    const permission = {
        role: accountSettings
            ? JSON.parse(accountSettings).cognitoGroup.toLowerCase()
            : null,
    };

    const result =
        permission &&
        permission.role &&
        permission.role.indexOf(requiredRole) === 0;

    return result;
};

export const builderAdminOnly = () => {
    const requiredRole = 'usergroup_admin';
    const requiredGroupType = 'builder';
    const accountSettings = getState('WalletUserSettings');
    const permission = {
        role: accountSettings
            ? JSON.parse(accountSettings).cognitoGroup.toLowerCase()
            : null,
        groupType:
            accountSettings && JSON.parse(accountSettings).usergroupType
                ? JSON.parse(accountSettings).usergroupType.toLowerCase()
                : null,
    };

    const result =
        permission &&
        permission.role &&
        permission.groupType &&
        permission.role.indexOf(requiredRole) === 0 &&
        permission.groupType.indexOf(requiredGroupType) === 0;

    return result;
};

export const supplierAdminOnly = () => {
    const requiredRole = 'usergroup_admin';
    const requiredGroupType = 'supplier';
    const accountSettings = getState('WalletUserSettings');
    const permission = {
        role: accountSettings
            ? JSON.parse(accountSettings).cognitoGroup.toLowerCase()
            : null,
        groupType:
            accountSettings && JSON.parse(accountSettings).usergroupType
                ? JSON.parse(accountSettings).usergroupType.toLowerCase()
                : null,
    };

    const result =
        permission &&
        permission.role &&
        permission.groupType &&
        permission.role.indexOf(requiredRole) === 0 &&
        permission.groupType.indexOf(requiredGroupType) === 0;

    return result;
};

// hyphen admins dont need to see this stuff
export const usergroupsOnly = () => {
    const requiredRole = ['usergroup_admin', 'usergroup_user'];
    const requiredGroupType = ['builder', 'supplier'];
    const accountSettings = getState('WalletUserSettings');
    const permission = {
        role: accountSettings
            ? JSON.parse(accountSettings).cognitoGroup.toLowerCase()
            : null,
        groupType:
            accountSettings && JSON.parse(accountSettings).usergroupType
                ? JSON.parse(accountSettings).usergroupType.toLowerCase()
                : null,
    };

    const result =
        permission &&
        permission.role &&
        permission.groupType &&
        requiredRole.includes(permission.role) &&
        requiredGroupType.includes(permission.groupType);

    return result;
};

export const hyphenOnly = () => {
    const requiredRole = 'hyphen_admin';
    const requiredGroupType = 'hyphen';
    const accountSettings = getState('WalletUserSettings');
    const permission = {
        role: accountSettings
            ? JSON.parse(accountSettings).cognitoGroup.toLowerCase()
            : null,
        groupType:
            accountSettings && JSON.parse(accountSettings).usergroupType
                ? JSON.parse(accountSettings).usergroupType.toLowerCase()
                : null,
    };

    const result =
        permission &&
        permission.role &&
        permission.groupType &&
        permission.role.indexOf(requiredRole) === 0 &&
        permission.groupType.indexOf(requiredGroupType) === 0;

    return result;
};

export const getUsergroupType = () => {
    const accountSettings = getState('WalletUserSettings');
    return accountSettings ? JSON.parse(accountSettings).usergroupType : null;
};

export const getUserAvatar = () => {
    const accountSettings = getState('WalletUserSettings');
    return accountSettings ? JSON.parse(accountSettings).avatar : null;
};

export const getUserEmail = () => {
    const accountSettings = getState('WalletUserSettings');
    return accountSettings ? JSON.parse(accountSettings).email : null;
};

export const getUserGroupId = () => {
    const accountSettings = getState('WalletUserSettings');
    return accountSettings ? JSON.parse(accountSettings).usergroup.id : null;
};

export const getSessionToken = () => {
    const session = getState('WalletCognitoUserSession');
    return session ? JSON.parse(session).IdToken : null;
};

// Deprecate this in favor of getAuthorData
export const getHistoryData = () => {
    const accountSettings = getState('WalletUserSettings');

    // use underscore rather than camelcase so it can be fed easily into postgres
    const history = {
        ip_addr: '199.99.99.99',
        email: accountSettings ? JSON.parse(accountSettings).email : null,
    };

    return history;
};

export const getAuthorData = () => {
    const accountSettings = getState('WalletUserSettings');

    // use underscore rather than camelcase so it can be fed easily into postgres
    const author = {
        ip_addr: '199.99.99.99',
        user_name: accountSettings ? JSON.parse(accountSettings).email : null,
    };

    return author;
};

export const signinCleanUp = () => {
    // removes WalletCognitoUserSession and WalletUserSettings
    localStorage.removeItem('auth');
    Cookie.remove('username');
    Cookie.remove('token');
};

export const getBusinessCardData = () => {
    const accountSettings = getState('WalletUserSettings');

    const { avatar, contactName, email, usergroup } =
        JSON.parse(accountSettings);

    const cardInfo = {
        avatar,
        usergroupName: usergroup.name,
        contactName,
        address1: usergroup.address1,
        address2: usergroup.address2,
        city: usergroup.city,
        state: usergroup.state,
        postalCode: usergroup.postalCode,
        email,
    };

    return cardInfo;
};

export const getAccountSettings = () => {
    const settings = getState('WalletUserSettings');

    const accountSettings = {
        usergroupName: settings ? JSON.parse(settings).usergroup.name : null,
        contactName: settings ? JSON.parse(settings).contactName : null,
        email: settings ? JSON.parse(settings).email : null,
        emailVerified: settings ? JSON.parse(settings).emailVerified : null,
        phoneNumber: settings ? JSON.parse(settings).phoneNumber : null,
        phoneNumberVerified: settings
            ? JSON.parse(settings).phoneNumberVerified
            : null,
        passwordModified: settings
            ? JSON.parse(settings).passwordModified
            : null,
        avatar: settings ? JSON.parse(settings).avatar : null,
    };

    return accountSettings;
};

export const formatAccountSettings = (settings) => {
    const accountSettings = {
        usergroupName: settings ? settings.usergroup.name : null,
        contactName: settings ? settings.contactName : null,
        email: settings ? settings.email : null,
        emailVerified: settings ? settings.emailVerified : null,
        phoneNumber: settings ? settings.phoneNumber : null,
        phoneNumberVerified: settings ? settings.phoneNumberVerified : null,
        passwordModified: settings ? settings.passwordModified : null,
        avatar: settings ? settings.avatar : null,
    };

    return accountSettings;
};

export const getFullnameAndEmail = () => {
    const accountSettings = getState('WalletUserSettings');

    const { contactName, email } = JSON.parse(accountSettings);

    const userInfo = {
        contactName,
        email,
    };

    return userInfo;
};
