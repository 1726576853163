import * as actionTypes from './types';

export const dashboardStart = () => ({
    type: actionTypes.DASHBOARD_START,
});

export const dashboardSuccess = (data) => ({
    type: actionTypes.DASHBOARD_SUCCESS,
    data,
});

export const dashboardFail = (error) => ({
    type: actionTypes.DASHBOARD_FAIL,
    error,
});

export const getDashboard = (data) => ({
    type: actionTypes.GET_DASHBOARD,
    data,
});
