import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const initialState = {
    isLoading: false,
    showDialog: false,
    error: null,
    data: null,
};

const putUserSupportStart = (state) =>
    updateObject(state, {
        isLoading: true,
        showDialog: false,
        error: null,
        data: null,
    });

const putUserSupportSuccess = (state) =>
    updateObject(state, {
        isLoading: false,
        showDialog: true,
        error: null,
    });

const putUserSupportFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        showDialog: false,
        error: action.error,
        data: null,
    });

const closeUserSupportDialogSuccess = (state) =>
    updateObject(state, {
        showDialog: false,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PUT_USER_SUPPORT_START:
            return putUserSupportStart(state);
        case actionTypes.PUT_USER_SUPPORT_SUCCESS:
            return putUserSupportSuccess(state);
        case actionTypes.CLOSE_USER_SUPPORT_DIALOG_SUCCESS:
            return closeUserSupportDialogSuccess(state);
        case actionTypes.PUT_USER_SUPPORT_FAIL:
            return putUserSupportFail(state, action);
        default:
            return state;
    }
};

export default reducer;
