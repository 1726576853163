import * as actionTypes from './types';

export const reportsStart = () => ({
    type: actionTypes.REPORTS_START,
});

export const reportsSuccess = (data) => ({
    type: actionTypes.REPORTS_SUCCESS,
    data,
});

export const reportsFail = (error) => ({
    type: actionTypes.REPORTS_FAIL,
    error,
});

export const getReports = (paymentId) => ({
    type: actionTypes.GET_REPORTS,
    paymentId,
});
