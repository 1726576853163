import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const initialState = {
    isLoading: false,
    isComplete: null,
    error: null,
    data: null,
};

const dashboardStart = (state) =>
    updateObject(state, { isLoading: true, isComplete: false, error: null });

const dashboardSuccess = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: true,
        error: null,
        data: action.data,
    });

const dashboardFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
        error: action.error,
        data: null,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DASHBOARD_START:
            return dashboardStart(state);
        case actionTypes.DASHBOARD_SUCCESS:
            return dashboardSuccess(state, action);
        case actionTypes.DASHBOARD_FAIL:
            return dashboardFail(state, action);
        default:
            return state;
    }
};

export default reducer;
