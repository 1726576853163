import { Box, Button, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { findIndex, map } from 'lodash';
import navBtnList from '../../../../../constants/strings/Dashboard/navbar';
import './dashboard-navbar.scss';
import ProgressSpinner from '../../../../UI/ProgressSpinner/ProgressSpinner';
import MenuDropDown from '../../../../UI/CustomElements/MenuDropDown/MenuDropDown';
import { routePath } from '../../../../../constants/strings/RoutePath';

const DashboardNavbar = () => {
    const location = useLocation();
    const walletUser = useSelector(
        (state) => state?.auth?.data?.WalletUserSettings,
    );
    const isLoading = useSelector((state) => state.dashboard.isLoading);
    const navigate = useNavigate();
    const [activeMenu, setActiveMenu] = useState({
        index: 1,
        value: navBtnList?.[1]?.label,
    });
    useEffect(() => {
        const activeIndex = findIndex(
            navBtnList,
            (menu) => menu.route === location.pathname,
        );
        setActiveMenu({
            index: activeIndex,
            value: navBtnList?.[activeIndex]?.label,
        });
    }, [location.pathname]);

    const filteredNavList = navBtnList.filter((item) => {
        if (item.userGroup === 'both') {
            return true;
        }
        if (item.key === 'batch_creation') {
            return (
                item.userGroup === walletUser?.usergroupType &&
                walletUser?.usergroup?.isPaymentBatch
            );
        }
        return item.userGroup === walletUser?.usergroupType;
    });
    const grouped = map(filteredNavList, 'label');

    const menuClickHandler = (event, e) => {
        setActiveMenu({ index: e, value: event?.target?.value });
        navigate(filteredNavList[e].route);
    };

    const navbarClickHandler = (loc) => {
        navigate(loc);
    };
    return (
        <Container maxWidth="xl">
            {isLoading && <ProgressSpinner />}
            {location.pathname !== routePath.accountSettings && (
                <Box
                    className="dash-nav-container"
                    sx={{ display: { lg: 'none', xs: 'flex' } }}
                >
                    <MenuDropDown
                        options={grouped}
                        userGroupType={walletUser?.usergroupType}
                        active={activeMenu}
                        menuClickHandler={(event, e) =>
                            menuClickHandler(event, e)
                        }
                    />
                </Box>
            )}
            <Box
                className="dash-nav-container"
                sx={{ display: { lg: 'flex', xs: 'none' } }}
            >
                {filteredNavList.map((item, index) => (
                    <Button
                        className={` dashboard-nav-btn ${item.className}`}
                        key={`${index}${item.route}`}
                        variant={
                            item.route === location.pathname
                                ? 'contained'
                                : 'text'
                        }
                        startIcon={item.icon}
                        onClick={() => navbarClickHandler(item.route)}
                    >
                        {walletUser &&
                        walletUser?.usergroupType === 'Builder' &&
                        item.label === 'Payor'
                            ? 'Payees'
                            : item.label}
                    </Button>
                ))}
            </Box>
        </Container>
    );
};
export default DashboardNavbar;
