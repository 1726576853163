import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import getApiParams from '../../constants/utilities/supplierSignup';
import { alertDialogSetSaga } from './alertDialog';

import * as actions from '../actions/index';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const getSupplierSignupResource = process.env.REACT_APP_VIEWS_SUPPLIER_SIGNUP;
const putSupplierSignupResource = process.env.REACT_APP_PUT_SUPPLIER_SIGNUP;

export function* getSupplierSignupSaga() {
    const url = baseUrl + getSupplierSignupResource;

    try {
        yield put(actions.getSupplierSignupStart());
        const response = yield axios.get(url);
        yield put(actions.getSupplierSignupSuccess(response.data.result));
    } catch (error) {
        if (error.response && error.response.data) {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error?.response?.data?.error,
                },
            });
        } else {
            yield call(alertDialogSetSaga, {
                data: {
                    open: true,
                    severityLevel: 'error',
                    message: error.message,
                },
            });
        }
        yield put(actions.getSupplierSignupFail(error.message));
    }
}

export function* putSupplierSignupSaga(action) {
    const url = baseUrl + putSupplierSignupResource;
    try {
        yield put(actions.stopLoader());
        yield put(actions.successPopupStatusChange(true));
        const body = getApiParams(action.data);

        const response = yield axios.post(
            url,
            JSON.stringify({
                ...body,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        // yield put(actions.stopLoader());
        // yield put(actions.successPopupStatusChange(true));
    } catch (error) {
        // yield put(actions.stopLoader());
        // if (error.response && error.response.data) {
        //     yield call(alertDialogSetSaga, {
        //         data: {
        //             open: true,
        //             severityLevel: 'error',
        //             message: error?.response?.data?.error,
        //         },
        //     });
        // } else if (error.message === 'Network Error') {
        //     yield call(alertDialogSetSaga, {
        //         data: {
        //             open: true,
        //             severityLevel: 'error',
        //             message: 'Email address already exist',
        //         },
        //     });
        // } else {
        //     yield call(alertDialogSetSaga, {
        //         data: {
        //             open: true,
        //             severityLevel: 'error',
        //             message: 'Something went wrong',
        //         },
        //     });
        // }
        // yield put(actions.putSupplierSignupFail(error.message));
    }
}
