import * as actionTypes from '../../actions/types';
import { updateObject } from '../../../constants/utility';
import {
    updatePaymentMappings,
    updatePaymentMappingsVisibility,
    updateBankInfoSubmittedVisibility,
} from '../../../constants/utilities/paymentMethod';

const initialState = {
    isLoading: false,
    isComplete: null,
    error: null,
    data: null,
};

const getPaymentMethodsStart = (state) =>
    updateObject(state, {
        isLoading: true,
        isComplete: false,
        error: null,
        data: null,
    });

const getPaymentMethodsSuccess = (state, action) => {
    const updatedState = updatePaymentMappingsVisibility(state, action);

    return updateObject(state, updatedState);
};

const getPaymentMethodsFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
        error: action.error,
        data: null,
    });

const putPaymentMethodStart = (state) =>
    updateObject(state, {
        isLoading: true,
        isComplete: false,
        error: null,
    });

const putPaymentMethodSuccess = (state, action) => {
    const { paymentMapping } = action.data;

    const { paymentMethodsMappings } = updatePaymentMappings(
        state.data.paymentMethodsMappings,
        paymentMapping,
    );

    return updateObject(state, {
        isLoading: false,
        isComplete: true,
        error: null,
        data: {
            paymentMethodsMappings,
            paymentMethods: state.data.paymentMethods,
            bankInfoSubmitted: state.data.bankInfoSubmitted,
        },
    });
};

const putPaymentMethodFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
        error: action.error,
        data: null,
    });

// Update RTP
const putBankInfoStart = (state) =>
    updateObject(state, { isLoading: true, isComplete: false, error: null });

const putBankInfoSuccess = (state, action) => {
    const updatedState = updateBankInfoSubmittedVisibility(state, action);

    return updateObject(state, updatedState);
};

const putBankInfoFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        isComplete: null,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PAYMENT_METHODS_START:
            return getPaymentMethodsStart(state);
        case actionTypes.GET_PAYMENT_METHODS_SUCCESS:
            return getPaymentMethodsSuccess(state, action);
        case actionTypes.GET_PAYMENT_METHODS_FAIL:
            return getPaymentMethodsFail(state, action);
        case actionTypes.PUT_PAYMENT_METHOD_START:
            return putPaymentMethodStart(state);
        case actionTypes.PUT_PAYMENT_METHOD_SUCCESS:
            return putPaymentMethodSuccess(state, action);
        case actionTypes.PUT_PAYMENT_METHOD_FAIL:
            return putPaymentMethodFail(state, action);
        case actionTypes.PUT_BANK_INFO_SUBMITTED_START:
            return putBankInfoStart(state);
        case actionTypes.PUT_BANK_INFO_SUBMITTED_SUCCESS:
            return putBankInfoSuccess(state, action);
        case actionTypes.PUT_BANK_INFO_SUBMITTED_FAIL:
            return putBankInfoFail(state, action);
        default:
            return state;
    }
};

export default reducer;
