import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const initialState = {
    isLoading: false,
    error: null,
    data: null,
    successPopupStatus: false,
};

const getSupplierSignupStart = (state) =>
    updateObject(state, { isLoading: true, error: null });

const getSupplierSignupSuccess = (state, action) =>
    updateObject(state, {
        isLoading: false,
        error: null,
        data: action.data,
    });

const getSupplierSignupFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        error: action.error,
        data: null,
    });

const putSupplierSignupStart = (state) =>
    updateObject(state, {
        isLoading: true,
        error: null,
        data: null,
    });

const putSupplierSignupSuccess = (state) =>
    updateObject(state, {
        isLoading: false,
        error: null,
    });

const putSupplierSignupFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        error: action.error,
        data: null,
    });
const successPopupStatusChange = (state, action) =>
    updateObject(state, {
        successPopupStatus: action.data,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SUPPLIER_SIGNUP_START:
            return getSupplierSignupStart(state);
        case actionTypes.GET_SUPPLIER_SIGNUP_SUCCESS:
            return getSupplierSignupSuccess(state, action);
        case actionTypes.GET_SUPPLIER_SIGNUP_FAIL:
            return getSupplierSignupFail(state, action);
        case actionTypes.PUT_SUPPLIER_SIGNUP_START:
            return putSupplierSignupStart(state);
        case actionTypes.PUT_SUPPLIER_SIGNUP_SUCCESS:
            return putSupplierSignupSuccess(state);
        case actionTypes.PUT_SUPPLIER_SIGNUP_FAIL:
            return putSupplierSignupFail(state, action);
        case actionTypes.SUCCESS_POPUP:
            return successPopupStatusChange(state, action);
        default:
            return state;
    }
};

export default reducer;
