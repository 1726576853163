import { put, call } from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions/index';
import { alertDialogSetSaga } from './alertDialog';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const recapthPath = process.env.REACT_APP_CONTACTUS_RECAPTCHA_CODE;
const sendMailPath = process.env.REACT_APP_CONTACTUS_SEND_MAIL;
const bannerPath = process.env.REACT_APP_HOMEPAGE_BANNER;
export function* getHomepageBanner() {
    const url = baseUrl + bannerPath;

    try {
        yield put(actions.getBannerStart());
        const response = yield axios.get(url);
        yield put(actions.getBannerSuccess(response?.data?.result?.rows));
    } catch (error) {
        yield put(actions.getBannerSuccess(null));
    }
}
export function* putHomepageSupportSaga(action) {
    const url = baseUrl + sendMailPath;

    try {
        const body = action.data;
        yield axios.post(
            url,
            JSON.stringify({
                ...body,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        yield put(actions.stopLoader());
        yield put(actions.dialogOpen(true));
    } catch (error) {
        yield put(actions.stopLoader());
        yield call(alertDialogSetSaga, {
            data: {
                open: true,
                severityLevel: 'error',
                message: 'notification-contactus-mail-send-fail',
            },
        });
    }
}
export function* putHomepageSendCodeSaga(action) {
    const url = baseUrl + recapthPath;
    yield put(actions.startLoader());
    try {
        const body = {
            username: action?.data?.username,
        };

        const captchaResponse = yield axios.post(
            url,
            JSON.stringify({
                ...body,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        yield put(actions.stopLoader());
        yield put(
            actions.verificationDialogOpen({
                dialogStatus: true,
                recaptcha: captchaResponse.data.result,
            }),
        );
    } catch (error) {
        yield put(actions.stopLoader());
        yield call(alertDialogSetSaga, {
            data: {
                open: true,
                severityLevel: 'error',
                message: 'notification-contactus-code-send-fail',
            },
        });
    }
}
