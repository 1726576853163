import * as actionTypes from '../actions/types';
import { updateObject } from '../../constants/utility';

const initialState = {
    isLoading: false,
    showDialog: false,
    error: null,
    codeData: null,
    mailData: null,
    bannerDate: null,
};
const putHomepageSupportStart = (state) =>
    updateObject(state, {
        isLoading: true,
        showDialog: false,
        error: null,
        data: null,
    });

const putHomepageSupportSuccess = (state) =>
    updateObject(state, {
        isLoading: false,
        showDialog: true,
        error: null,
    });

const putHomepageSupportFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        showDialog: false,
        error: action.error,
        data: null,
    });

const closeHomepageSupportDialogSuccess = (state) =>
    updateObject(state, {
        showDialog: false,
    });
const putHomepageCodeSendStart = (state) =>
    updateObject(state, {
        isLoading: true,
        showDialog: false,
        error: null,
        data: null,
    });

const putHomepageCodeSendSuccess = (state) =>
    updateObject(state, {
        isLoading: false,
        showDialog: true,
        error: null,
    });

const putHomepageCodeSendFail = (state, action) =>
    updateObject(state, {
        isLoading: false,
        showDialog: false,
        error: action.error,
        data: null,
    });

const closeHomepageCodeSendDialogSuccess = (state) =>
    updateObject(state, {
        showDialog: false,
    });

const sendMail = (state, action) =>
    updateObject(state, {
        sendData: action.data,
    });
const getBannerSuccess = (state, action) =>
    updateObject(state, {
        bannerData: action.data,
        isLoading: false,
    });
const getBanner = (state) => updateObject(state);
const getBannerStart = (state, action) =>
    updateObject(state, {
        isLoading: true,
    });
const sendContactusCode = (state, action) =>
    updateObject(state, {
        mailData: action.data,
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PUT_HOMEPAGE_SUPPORT_START:
            return putHomepageSupportStart(state);
        case actionTypes.PUT_HOMEPAGE_SUPPORT_SUCCESS:
            return putHomepageSupportSuccess(state);
        case actionTypes.CLOSE_HOMEPAGE_SUPPORT_DIALOG_SUCCESS:
            return closeHomepageSupportDialogSuccess(state);
        case actionTypes.PUT_HOMEPAGE_SUPPORT_FAIL:
            return putHomepageSupportFail(state, action);
        case actionTypes.PUT_HOMEPAGE_CODESEND_START:
            return putHomepageCodeSendStart(state);
        case actionTypes.PUT_HOMEPAGE_CODESEND_SUCCESS:
            return putHomepageCodeSendSuccess(state);
        case actionTypes.CLOSE_HOMEPAGE_CODESEND_DIALOG_SUCCESS:
            return putHomepageCodeSendFail(state);
        case actionTypes.PUT_HOMEPAGE_CODESEND_FAIL:
            return closeHomepageCodeSendDialogSuccess(state);
        case actionTypes.SEND_MAIL:
            return sendMail(state, action);
        case actionTypes.GET_BANNER:
            return getBanner(state, action);
        case actionTypes.GET_BANNER_START:
            return getBannerStart(state, action);
        case actionTypes.GET_BANNER_SUCCESS:
            return getBannerSuccess(state, action);
        case actionTypes.SEND_CONTACTUS_CODE:
            return sendContactusCode(state, action);
        default:
            return state;
    }
};

export default reducer;
