/* eslint-disable camelcase */
import { put } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions/index';

import { getSessionToken, getUserEmail } from '../../constants/session';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const resource = process.env.REACT_APP_PAYMENTS;

export default function* getPaymentsSaga(action) {
    try {
        yield put(actions.paymentsStart());

        const {
            batchId,
            limit,
            offset,
            payment_num,
            payee_name,
            payor_name,
            payment_method,
            payment_status,
            min_amount,
            max_amount,
            exact_amount,
            memo,
            from_date,
            to_date,
            order_by,
            sort_order,
        } = action.data;
        yield put(actions.tableFilter(action.data));
        yield put(
            actions.tableSortValues({
                columnName: order_by ?? '',
                columnOrder: sort_order ?? 'asc',
            }),
        );
        const email = getUserEmail();
        const url = baseUrl + resource;

        const sessionToken = getSessionToken();

        const response = yield axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionToken,
            },
            params: {
                username: email,
                limit,
                offset,
                batchId,
                payment_num,
                payee_name,
                payor_name,
                payment_method,
                min_amount,
                max_amount,
                exact_amount,
                payment_status,
                memo,
                from_date,
                to_date,
                order_by,
                sort_order,
            },
        });

        const data = {
            payments: response.data.result.payments
                ? response.data.result.payments
                : null,
        };

        yield put(actions.paymentsSuccess(data));
    } catch (error) {
        yield put(actions.paymentsFail(error));
    }
}
