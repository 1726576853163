import jwtDecode from 'jwt-decode';

export const getState = (key) => {
    let value = null;
    try {
        switch (key) {
            case 'WalletCognitoUserSession':
                value = JSON.parse(localStorage.getItem('auth')).data
                    .WalletCognitoUserSession;
                return JSON.stringify(value);
            case 'WalletUserSettings':
                value = JSON.parse(localStorage.getItem('auth')).data
                    .WalletUserSettings;
                return JSON.stringify(value);
            default:
                return null;
        }
    } catch (err) {
        return undefined;
    }
};
// state management: get, set to local storage
// May need to enable the compression later on
export const loadState = () => {
    try {
        const auth = localStorage.getItem('auth');
        if (auth === null) {
            return undefined;
        }
        const sessionToken = JSON.parse(auth).data.WalletCognitoUserSession;
        const decodedTime = jwtDecode(sessionToken.AccessToken);
        const currenttime = new Date().getTime() / 1000;
        if (decodedTime.exp < currenttime) {
            localStorage.clear();
            return undefined;
        }
        return { auth: JSON.parse(auth) };
    } catch (err) {
        // localStorage.clear();
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        if (state && state.auth && state.auth.data) {
            const lStorageData = { ...state.auth, isLoading: false };
            localStorage.setItem('auth', JSON.stringify(lStorageData));
        }
        // localStorage.setItem('wallet', LZString.compress(stringifiedState));
    } catch (err) {
        return undefined;
    }
};
