/* eslint-disable import/prefer-default-export */
import { includes } from 'lodash';

export const routePath = {
    homepage: '/',
    aboutus: '/aboutus',
    login: '/login',
    register: '/register',
    kycSubmission: '/kycsubmission',
    forgotpassword: '/forgotpassword',
    confirmforgotpassword: '/confirmforgotpassword',
    updatepassword: '/updatepassword',
    codechallenge: '/codechallenge',
    notFound: '/404',
    maintenance: '/maintenance',
    dashboard: '/dashboard',
    support: '/support',
    payments: '/payments',
    pendingPayments: '/pendingpayments',
    paymentMethods: '/payment-methods',
    relationships: '/relationships',
    walletPay: '/batch-creation',
    howWalletWorks: '/how-wallet-works',
    faq: '/faq',
    accountSettings: '/account-settings',
};

export const dashLayoutAuthorized = (url) => {
    const authorizedUrls = [
        routePath.dashboard,
        routePath.support,
        routePath.payments,
        routePath.pendingPayments,
        routePath.paymentMethods,
        routePath.relationships,
        routePath.walletPay,
        routePath.howWalletWorks,
        routePath.faq,
        routePath.accountSettings,
    ];
    return includes(authorizedUrls, url);
};
export const allowAutoRerouting = (url) => {
    const routingUrls = [
        routePath.login,
        routePath.register,
        routePath.homepage,
        routePath.aboutus,
    ];
    return includes(routingUrls, url);
};
