/* eslint-disable import/prefer-default-export */
const defaultColorPallets = {
    pureWhite: {
        main: '#FFF',
    },
    pureBlack: {
        main: '#000',
    },

    background: {
        default: '#e4e4e4',
    },
    primary: {
        main: '#F47521',
        contrastText: '#fff',
    },
    secondary: {
        main: '#ababab',
        contrastText: '#fff',
    },
    contentDivider: { main: 'rgba(255,255,255,.5)' },
};
export const appTheme = (mode) => ({
    typography: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        subtitle1: {
            color: '#828282',
            fontSize: 12,
            fontWeight: 400,
        },
    },
    palette: {
        mode: 'light',
        ...(mode === 'light'
            ? {
                  ...defaultColorPallets,
                  white: { main: '#000' },
                  popupBackground: { main: '#fff' },
                  emailPopupBackground: { main: '#fff' },
                  contactInfoBackground: { main: '#D0CFCD' },
                  black: { main: '#fff' },
                  loginButtonNavbar: { main: '#000' },
                  switchTrackColor: { main: '#fff' },
                  greyBackground: { main: '#363636' },
                  remainingcharFooter: { main: '#343434' },
                  dashboardMainBackgroud: { main: 'rgb(242,247,255)' },
                  dashboardContainerBackgroud: { main: '#fff' },
                  dashboardPaymethodContainer: { main: '#D9D9D9' },
                  dashboardRelationshipPaycard: { main: '#fff' },
                  dashboardRelationshipSinglecard: { main: '#F8F8F8 ' },
                  dashboardPaymethodRadioContainer: { main: '#F8F8F8 ' },
                  dashboardAnaliticsTotPayBackground: { main: '#FFE2E5' },
                  dashboardAnaliticsTotAmtBackground: { main: '#FFF4DE' },
                  dashboardAnaliticsTotinvoiceBackground: { main: '#DCFCE7' },
                  dashboardAnaliticsRecentPayBackground: { main: '#F3E8FF' },
                  accountinformationwrapperBackground: { main: '#D9D9D9' },
                  walletPayDetailsDialogContentBorder: { main: '#D0D5DD' },
                  walletPayDetailsDialogContentBackground: { main: '#F8F8F8' },
                  innerpanel: { main: '#D9D9D9' },
                  graphDropdownBorder: { main: '#C3D3E2' },
                  textcolor: { main: '#000' },
                  popupBG: { main: '#fff' },
                  FaqSidebar: { main: '#dcd8d7' },
              }
            : {
                  ...defaultColorPallets,
                  white: { main: '#fff' },
                  popupBackground: { main: '#343434' },
                  greyBackground: { main: '#000' },
                  emailPopupBackground: { main: '#343434' },
                  contactInfoBackground: { main: '#343434' },
                  black: { main: '#000' },
                  loginButtonNavbar: { main: '#cfcfcf' },
                  switchTrackColor: { main: '#F47521' },
                  remainingcharFooter: { main: '#b2b2b2' },
                  dashboardMainBackgroud: { main: '#000' },
                  dashboardContainerBackgroud: { main: '#121212' },
                  dashboardPaymethodContainer: { main: '#343434' },
                  dashboardRelationshipPaycard: { main: '#121212' },
                  dashboardRelationshipSinglecard: { main: '#000' },
                  dashboardPaymethodRadioContainer: { main: '#000 ' },
                  dashboardAnaliticsTotPayBackground: { main: '#000' },
                  dashboardAnaliticsTotAmtBackground: { main: '#000' },
                  dashboardAnaliticsTotinvoiceBackground: { main: '#000' },
                  dashboardAnaliticsRecentPayBackground: { main: '#000' },
                  accountinformationwrapperBackground: { main: '#343434' },
                  walletPayDetailsDialogContentBorder: { main: '#000' },
                  walletPayDetailsDialogContentBackground: { main: '#000' },
                  innerpanel: { main: '#343434' },
                  graphDropdownBorder: { main: '#fff' },
                  textcolor: { main: '#fff' },
                  popupBG: { main: '#343434' },
                  FaqSidebar: { main: '#343434' },
              }),
    },
    overrides: {
        MuiCheckbox: {
            colorSecondary: {
                color: '#808080',
                '&$checked': {
                    color: '#F47521',
                },
            },
        },
        MuiStepIcon: {
            root: {
                '&$completed': {
                    color: '#3A4043',
                },
                '&$active': {
                    color: '#F47521',
                },
            },
            active: {},
            completed: {},
        },
        MuiStepLabel: {
            label: {
                '&$completed': {
                    color: '#3A4043',
                },
                '&$active': {
                    color: '#F47521',
                    fontWeight: 600,
                },
            },
            active: {},
            completed: {},
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#F47521',
            },
        },
        MuiPickersDay: {
            day: {
                color: '#3A4043',
                backgroundColor: '#ffffff',
            },
            container: {
                backgroundColor: '#ffffff',
            },
            daySelected: {
                backgroundColor: '#F47521',
                color: '#ffffff',
            },
            dayDisabled: {
                color: '#F47521',
            },
            current: {
                color: '#F47521',
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: '#F47521',
            },
        },
        MuiTableSortLabel: {
            root: {
                '&:hover': {
                    color: '#3A4043',
                    '&& $icon': {
                        opacity: 1,
                        color: '#3A4043',
                    },
                },
                '&$active': {
                    color: '#F47521',
                    '&& $icon': {
                        opacity: 1,
                        color: '#F47521',
                    },
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 990,
            xl: 1500,
        },
    },
});
